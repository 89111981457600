import { SagaIterator } from 'redux-saga';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {SET_PROFILE, LOGIN_REQUEST, UPDATE_USER_ATTRIBUTES, UPDATE_ON_BOARDING, GET_OTHER_USER_DETAILS,} from './types';
import {callUstApi} from "../../helper/api.helper";


export function* loginRequest() {
  yield takeEvery(LOGIN_REQUEST, function* (): SagaIterator {
    try {
      const response = yield call(login);
      const accessToken: string = response.accessToken;
      const profile: Object = response.user;
      yield put({
        type: SET_PROFILE,
        payload: {
          accessToken,
          profile
        }
      });
    } catch (error) {
    }
  });
}
export function* updateUserAttributes(){
  yield takeEvery(UPDATE_USER_ATTRIBUTES, function* ({ payload }: any): SagaIterator {
    try {
      const response = yield call(updateUserAttributesAPi, payload);
      yield put({
        type: UPDATE_ON_BOARDING,
        payload: response.length > 0 ? response[0] : null
      });
    } catch (error) {
      console.log({ error });

    }
  });
}
export function* getOtherUserDetailsSaga(){
  yield takeEvery(GET_OTHER_USER_DETAILS, function* (payload: any): SagaIterator {
    try {
      const response = yield call(getOtherUserDetailsApi, payload.payload);
      console.log({response})
      if(response){
        payload.resolve(response)
      }else {
        payload.reject()
      }
    } catch (error) {
      console.log({ error });
      payload.reject()

    }
  });
}

function login(): any {
  const info: Object = {
    user: {
      name: 'umair',
      email: "umair.riaz@exponentoc.com",
      password: "123456",
    },
    accessToken: "q11cr1234"
  }
  return info;
}
async function updateUserAttributesAPi(payload:any) {
  return await callUstApi({ url: '/updateUserAttributes', payload, method: 'POST' })
}
async function getOtherUserDetailsApi(payload:any) {
  return await callUstApi({ url: '/get-user', payload, method: 'POST' })
}


export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(updateUserAttributes),
    fork(getOtherUserDetailsSaga)
  ]);
}
