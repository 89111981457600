import axios from 'axios';
import { history } from '../redux/store';
import api from '../config';
import config from './../config';
import { getUToken } from './auth.helper';
import { checkAndRefreshToken } from './api.helper';

const axiosApi = axios.create({
  baseURL: api.apiUrl
})

axiosApi.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response.data);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosApiGateway = axios.create({
  baseURL: api.apiGateway
});

axiosApiGateway.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(typeof response.data === 'string' ?  JSON.parse(response.data) : response.data);
  });
}, async error => {
  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
    console.log(error.response.status);
  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.appUrl;
  } else if ([410].indexOf(error.response.status) > -1) {
        const token = getUToken();
        let _token =  await checkAndRefreshToken(token)
        // Retry the original request with the new access token
        const originalRequest = error.config;
        originalRequest.headers['Authorization'] = `Bearer ${_token}`;
        return axiosApiGateway(originalRequest);
  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

// new instence for youtube api
const axiosApiYtGateway = axios.create({
  baseURL: api.apiGateway
});

axiosApiYtGateway.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(typeof response.data === 'string' ?  JSON.parse(response.data) : response.data);
  });
}, async error => {
  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
    console.log(error.response.status);
  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.appUrl;

  } else if ([410].indexOf(error.response.status) > -1) {
    const token = getUToken();
    let _token = await checkAndRefreshToken(token)
    // Retry the original request with the new access token
    const originalRequest = error.config;
    originalRequest.headers['Authorization'] = `Bearer ${_token}`;
    return axiosApiYtGateway(originalRequest);
  }
  else {
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
})

const axiosStudioApi = axios.create({
  baseURL: api.studioServer
})

axiosStudioApi.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosVumuEmbed = axios.create({
  baseURL: config.VUMU_API_GATEWAY
})

axiosVumuEmbed.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosUst = axios.create({
  baseURL: config.UST_API_GATEWAY
})

axiosUst.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

export {
  axiosApi,
  axiosApiGateway,
  axiosApiYtGateway,
  axiosStudioApi,
  axiosVumuEmbed,
  axiosUst
}
