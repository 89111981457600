import { LOADED_SUCCESS, LOADING_APP, UPGRADE_MODAL_VISIBLE, GET_MONTHLY_VIDEOS_REQUEST } from './types';

export const AppLoaded = (payload: any) => (dispatch: any) => {
  dispatch({ type: LOADED_SUCCESS })
}

export const AppLoading = (payload: any) => (dispatch: any) => {
  dispatch({ type: LOADING_APP })
}

export const upgradeModalVisibiltyAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: UPGRADE_MODAL_VISIBLE, payload });
}

export const getMonthlyVideosAction = (payload: any) => (dispatch: any) => {
  // dispatch({ type: GET_MONTHLY_VIDEOS_REQUEST, payload })
}