import { GET_AWS_CREDENTIALS_ACTION } from './types';

export const getAWSCredentialsAction = (payload: any) => (dispatch: any) => {
    return new Promise<any>((resolve, reject) => {
      dispatch({
        type: GET_AWS_CREDENTIALS_ACTION,
        payload,
        resolve,
        reject
      })
    })
  }