import { Reducer } from 'redux';
import { SET_SOCKET_CONNECTED, SET_RE_CONNECTING, CONNECTION_TRIES_RESET } from '../action_types';
import { ActionType } from '../interface';

interface initalStateProps {
  connected: boolean,
  tries: number 
}

const initState: initalStateProps = {
  connected: false,
  tries: 0
}

const connectionReducer: Reducer<initalStateProps, ActionType> = (state = initState, action) => {
    switch (action.type) {
        case SET_SOCKET_CONNECTED: {
            return { ...state, connected: action.payload.value, reconnecting: false, tries: state.tries + 1 }
        }

        case SET_RE_CONNECTING: {
            return { ...state, connected: false, reconnecting: true }
        }

        case CONNECTION_TRIES_RESET: {
            return { ...state, tries: action.payload || 0 }
        }

        default:
            return state;
    }
}

export default connectionReducer