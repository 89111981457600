import * as types from './types';
import * as Thunk from './thunks';

export const fetchAllProAndDir = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_PRO_AND_DIR, payload });
}
export const fetchWorkspace = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.FETCH_WORKSPACE_ONLY, payload, resolve, reject });
  })
}
export const fetchWorkspaces = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.FETCH_WORKSPACES, payload, resolve, reject });
  })
}
export const createOnBoarding = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CREATE_ONBOARDING, payload, resolve, reject });
  })
}
export const fetchProject = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.FETCH_PROJECT, payload, resolve, reject });
  })
}
export const updateWorkspace = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CALL_UPDATE_WORKSPACE, payload, resolve, reject });
  })
}
export const updateDomain = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CALL_UPDATE_DOMAIN, payload, resolve, reject });
  })
}

export const createWorkspaceTemplate = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.SAVE_ALL_BRANDING_DATA, payload, resolve, reject });
  })
}

export const createNewDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.CREATE_NEW_DIR, payload });
}

export const updateDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_DIR, payload });
}

export const deleteDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.DELETE_DIR, payload });
}

export const updateProjectAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_PROJECT, payload });
}

export const deleteProjectAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.DELETE_PROJECT, payload });
}

export const updateFolderChain = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_FOLDER_CHAIN, payload });
}

export const resetFolderChain = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.RESET_FOLDER_CHAIN, payload });
}

export const updateCurrentDirectoryAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_CURRENT_DIRECTORY, payload });
}

export const addProjectAction = (payload: any) => (dispatch: any) => {
  console.log({ payload });
  dispatch({ type: types.ADD_PROJECT, payload })
}
export const addVumuProjectUpload = (payload: any) => (dispatch: any) => {
  console.log({ payload });
  dispatch({ type: types.ADD_VUMU_PROJECT_UPLOAD, payload })
}

export const setIframeVisibleAction = (payload: any) => (dispatch: any) => {
  console.log({ payload });
  dispatch({ type: types.SHOW_IFRAME, payload })
}

export const fileBrowserLoadingAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FB_LOADING, payload })
}

export const renderVideo = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.RENDER_VIDEO, payload });
}

export const addProjectKeeviAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
      type: types.ADD_PROJECT_KEEVI,
      payload,
      resolve,
      reject
    })
  })
}

export const addProjectKeeviPromiseAction = (payload: any) => (dispatch: any) => {
  return dispatch(Thunk.addProjectKeeviSaga({ type: types.ADD_PROJECT_KEEVI, payload }))
}

export const addProjectVumuAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
      type: types.ADD_PROJECT_VUMU,
      payload,
      resolve,
      reject
    })
  })
}

export const updateVumuProjectAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_PROJECT_VUMU, payload });
}

export const fetchTemplates = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_TEMPLATES, payload });
}

export const fetchCategoriesList = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_CATEGORIES, payload });
}

export const setProjectsToEmptyAction = () => (dispatch: any) => {
  dispatch({ type: types.SET_PROJECTS_TO_EMPTY });
}

export const getStatisticsAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.GET_STATISTICS_REQUEST, payload })
}

export const setNextPageAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.SET_NEXT_PAGE, payload });
}


export const getAllDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_DIR_REQ, payload });
}


export const getAllProAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_PRO_REQ, payload });
}

export const getAllDirFromWorkspaceAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_DIR_WRK_REQ, payload });
}
export const downloadYoutubeVideoUrl = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
          type: types.DOWNLOAD_YOUTUBE_VIDEO_URL,
          payload,
          resolve,
          reject
      })
  })
}

export const fetchSupportedLanguagesAction = () => async (dispatch:any) => {
  dispatch({ type:types.FETCH_SUPPORTED_LANGUAGES });
};

export const createTranscriptionAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CREATE_TRANSCRIPTION, payload, resolve, reject });
  })
}

export const setUserStatesActions = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.SET_USER_STATES, payload, resolve, reject });
  })
}

export const createReframeCoordinates = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CREATE_COORDINATES, payload, resolve, reject });
  })
}

export const trimVideo = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.TRIM_VIDEO, payload, resolve, reject });
  })
}