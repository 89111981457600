import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import "./style.scss";
import { logout } from "../../helper/ust.helper"
import { addProjectAction, addVumuProjectUpload, addProjectKeeviAction } from '../../redux/file-browser/actions';
import { getAWSCredentialsAction } from '../../redux/aws/actions';
import { message, Upload, Button } from "antd";
import config from './../../config'
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from "uuid";
// import { UserOutlined, SearchOutlined, UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import UserOutlined from "../../assets/icons/userAvatar.svg"

interface Iprops {
  children?: any;
  history?: any;
}


let thumbnailUrl: any = null;

const Header = (props: Iprops) => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector((state: RootState) => state.browserReducer.currentWorkspace);
  const currentParentDirectory = useSelector((state: RootState) => state.browserReducer.currentParentDirectory);
  const currentUserProfile = useSelector((state: RootState) => state.authReducer.profile);
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadProps: (any) = {
    name: 'file',
    multiple: false,
    accept: 'video/*',
    async customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
      const creds = await dispatch(getAWSCredentialsAction());

      AWS.config.update({
        accessKeyId: creds.AccessKeyId,
        secretAccessKey: creds.SecretAccessKey,
        sessionToken: creds.SessionToken,
        region: config.REGION,
        httpOptions: { timeout: 0 }
      });

      await capture(file)
      const S3 = new AWS.S3();
      let fileName = file.name.replace(/\s+/g, '');
      fileName = uuidv4() + fileName
      var opts = { queueSize: 1, partSize: 1024 * 1024 * 5 };
      const objParams = {
        Bucket: config.BUCKET_S3_DESTINATION,
        Key: config.UPLOADED_VIDEOS_FOLDER + '/' + fileName,
        Body: file,
        ContentType: file.type // TODO: You should set content-type because AWS SDK will not automatically set file MIME
      };
      S3.upload(objParams, opts)
        .on("httpUploadProgress", function ({ loaded, total }) {
          setUploadProgress(Math.round((loaded / total) * 100))
          onProgress(
            {
              percent: Math.round((loaded / total) * 100)
            },
            file
          );
        })
        .send(function (err, data: any) {
          if (err) {
            onError();
            console.log("Something went wrong");
            console.log(err.code);
            console.log(err.message);
          } else {
            onSuccess(data.response, {
              ...file,
              s3Uri: config.DESTINATION_MEDIA_CDN + config.UPLOADED_VIDEOS_FOLDER + '/' + fileName
            });
            console.log("SEND FINISHED");
          }
        });
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      // if (info.event && info.event.type === 'progress') {
      // 	console.log('file uploading', info);
      // }
      if (status === 'removed') {

      }
      if (status === 'done') {
        setTimeout(() => {
          setUploadProgress(0)
        }, 3000)
        // if (config.APP === 'KEEVI') {
          uploadKeeviVideoHandler(info.file.xhr.s3Uri, info.file.name)
        // } else if (config.APP === 'VUMU') {
        //   uploadVumuVideoHandler(info.file.xhr.s3Uri)
        // } else if (config.APP === 'VIDPROPOSALS') {
        //   uploadVidProposalsVideoHandler(info.file.xhr.s3Uri)
        // }
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }

  };
  const objectId = () => {
    return uuidv4().replace(/-/g, '').substr(0, 24).toLowerCase();
  }
  const recordVideoHandler = (e: any) => {
    e.preventDefault();
    const info = {
      workspace: currentWorkspace?._id,
      name: 'New Project',
      parentDirectory: currentParentDirectory,
    };
    dispatch(addProjectAction(info));
  }
  const uploadVumuVideoHandler = (s3Uri: any) => {
    const info = {
      _id: objectId(),
      videoUrl: s3Uri,
      thumbnailUrl,
      createdBy: currentUserProfile?.userId || null,

    };
    dispatch(addVumuProjectUpload(info));
  }

  const uploadVidProposalsVideoHandler = (s3Uri: any) => {
    const info = {
      _id: objectId(),
      videoUrl: s3Uri,
      thumbnailUrl,
      createdBy: currentUserProfile?.userId || null,

    };
    dispatch(addVumuProjectUpload(info));
  }

  const uploadKeeviVideoHandler = (s3Uri: any, name: string) => {
    const info = {
      workspace: currentWorkspace?._id,
      userId: currentUserProfile?.userId,
      name,
      parentDirectory: currentParentDirectory,
      mp4Url: s3Uri,
      thumbnailUrl,
      orignalUrl: s3Uri,
      s3Location: s3Uri,
      createdBy: currentUserProfile?.userId || null,
    };
    dispatch(addProjectKeeviAction(info));
  }

  const capture = async (file: any) => {
    // convert file to blob source
    let src = URL.createObjectURL(file);
    let video = document.createElement('video');
    video.src = src
    let w = 900
    let h = 600
    video.style.width = '900px'
    video.style.height = '500px'
    video.currentTime = 2
    video.muted = true
    await video.play()
    let canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    canvas.getContext('2d')?.drawImage(video, 0, 0, w, h);
    // canvas.toDataURL("image/jpg");
    canvas.toBlob((blob: any) => {
      let url = URL.createObjectURL(blob);
      video.pause();
      const thumbnailImage = new File([url], Date.now() + '.jpg', { type: "image/jpg", lastModified: Date.now() });
      const S3 = new AWS.S3();
      const fileName = thumbnailImage.name.replace(/\s+/g, '');
      const objParams = {
        Bucket: config.BUCKET_S3_DESTINATION,
        Key: config.THUMBNAILS_FOLDER + '/' + fileName,
        Body: blob,
        ContentEncoding: 'base64',
        ContentType: "image/jpg" // TODO: You should set content-type because AWS SDK will not automatically set file MIME
      };
      setUploadProgress(1)
      S3.upload(objParams)
        .on("httpUploadProgress", function ({ loaded, total }) {
          // setUploadProgress(Math.round((loaded / total) * 100))
          // console.log('progress', (Math.round((loaded / total) * 100)))
        })
        .send(function (err, data: any) {
          if (err) {
            console.log("Something went wrong");
            console.log(err.code);
            console.log(err.message);
          } else {
            const thumbnailUri = config.DESTINATION_MEDIA_CDN + config.THUMBNAILS_FOLDER + '/' + fileName
            thumbnailUrl = thumbnailUri
            console.log("SEND For Theumbnail", thumbnailUri);
          }
        });
      console.log({ url, thumbnailImage })

    })



  }
  return (
    // <section className="nav-section shadowBottom header">
    //   <nav className="navbar navbar-expand-sm light-dark d-flex justify-content-between">
    //     <a className="navbar-brand" href={api.appUrl}>
    //       <img src="/images/logo.png" className="brandImg" alt="logo" />
    //     </a>
    //     {profile && (
    //       <DropButton first_name={profile.first_name}>
    //         <span>{profile.first_name}</span>
    //         <span style={{ color: "rgb(130, 185, 178)" }}>{profile.email}</span>
    //         <hr className="divider-line-header" />
    //         <a onClick={(e) => window.location.href = `${api.appUrl}/dashboard`}>My Video</a>
    //         <a onClick={(e) => window.location.href = `${api.appUrl}/profile`}>Profile</a>
    //         <a onClick={(e) => logout()}>Logout</a>
    //       </DropButton>
    //     )}
    //   </nav>
    // </section>

    <nav className="navbar navbar-expand navbar-light dashboard-nav">
      {/* <a className="navbar-brand" href="#">
        <img src={config.DESTINATION_MEDIA_CDN + "assets/logo.png"} style={{ maxWidth: '40px', width: '100%' }} alt="keevi dashboar logo" />
      </a> */}

      <ul className="navbar-nav ml-auto mt-lg-0">
        <form className="form-inline my-2 my-lg-0 headerBtn">
          <Upload {...uploadProps} showUploadList={false}>
            {/*<Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
            {/*<Button className="btnBeta mr-3 px-2 px-md-4 px-sm-3" disabled={uploadProgress > 0} onClick={(e) => e.preventDefault()} >{uploadProgress > 0 ? `Uploading... ${uploadProgress} %`: uploadProgress === 100 ?  'Uploaded' :  <div> <CloudUploadOutlined className="mr-0 mr-md-1" /> <span className="hideOnTab">Upload a Video</span></div>}</Button>*/}
          </Upload>
          {/*{(config.APP === 'VIDPROPOSALS') && <Button className="btnAlpha mr-3 px-2 px-md-4 px-sm-3" onClick={(e) => recordVideoHandler(e)}><div>Start<span className="hideOnTab"> from Scratch</span></div></Button>}*/}
          {/*{(config.APP === 'VUMU' ) && <Button className="btnAlpha mr-3 px-2 px-md-4 px-sm-3" onClick={(e) => recordVideoHandler(e)}><div>Record<span className="hideOnTab"> a video</span></div></Button>}*/}
          {/* <div className="search">
                  <input type="text" placeholder="search"/>
                  <div className="symbol">
                      <SearchOutlined className="lens" />
                  </div>
              </div> */}
          <div className="dropdown noIcon profileDropdown"> 
            <div className="user-avatar dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
              {!currentUserProfile?.picture || currentUserProfile?.picture === 'https://newpicture.png' ? <img className="defaultAvatar" src={UserOutlined}  /> : <img src={currentUserProfile?.picture === '' ? currentUserProfile?.picture : currentUserProfile?.picture} className="avatar" alt="" />}
            </div>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              {/* <a className="dropdown-item" href="#" >{currentUserProfile?.email}</a> */}
              <a className="dropdown-item" href="#" onClick={() => window.location.href = `https://${currentWorkspace?.workspaceDomain ? currentWorkspace?.workspaceDomain + "." : ""}${config.ustCookieDomain + '/account/profile'}`}>My Account</a>
              <a className="dropdown-item" href="#" onClick={() => window.location.href = `https://${currentWorkspace?.workspaceDomain ? currentWorkspace?.workspaceDomain + "." : ""}${config.ustCookieDomain + '/account/plans'}`}>Plans & Billing</a>
              {/* <a className="dropdown-item" href="#" onClick={() => window.location.href = `https://${currentWorkspace?.workspaceDomain ? currentWorkspace?.workspaceDomain + "." : ""}${config.ustCookieDomain + '/account/privacy'}`}>Privacy</a> */}
              {currentUserProfile?.parentAccount == "CHILD" ? "" : <a className="dropdown-item" href="#" onClick={() => window.location.href = `https://${currentWorkspace?.workspaceDomain ? currentWorkspace?.workspaceDomain + "." : ""}${config.ustCookieDomain + '/account/accountmanagement'}`}>Account Management</a>}
              <a className="dropdown-item" target="_blank" href="https://www.notion.so/infniteg/5283c564f7dc4d37945e6eb82beef7a0?v=81b374e67433476389e15b14185f7c64" >Keevi Help Desk</a>
              {/* <a className="dropdown-item" href="#" onClick={() => window.location.href = `https://${currentWorkspace?.workspaceDomain ? currentWorkspace?.workspaceDomain + "." : ""}${config.ustCookieDomain + '/account/upgrades'}`}>Upgrade</a> */}

              <a className="dropdown-item" href="#" onClick={(e) => logout()}>Logout</a>

            </div>
          </div>
        </form>
      </ul>
    </nav>
  );
};

export default Header;
