export const LINE_POSITION = 'LINE_POSITION';
export const CAPTION_POSITION = 'CAPTION_POSITION';
export const FONT_FAMILY = 'FONT_FAMILY';
export const FONT_WEIGHT = 'FONT_WEIGHT';
export const TOGGLE_UNDERLINE = 'TOGGLE_UNDERLINE';
export const TOGGLE_ITALIC = 'TOGGLE_ITALIC';
export const UPPERCASE = 'UPPERCASE';
export const COUNT = 'COUNT';
export const AI_KEYWORD_HEIGHLIGHTER = 'AI_KEYWORD_HEIGHLIGHTER';
export const AI_KEYWORD_STROKE_HEIGHLIGHTER = 'AI_KEYWORD_STROKE_HEIGHLIGHTER';
export const CHANGE_LAYOUT_SETTING = 'CHANGE_LAYOUT_SETTING';
// export const FONT_SHADOW_COLOR = 'FONT_SHADOW_COLOR';
// export const FONT_SHADOW_X = 'FONT_SHADOW_X';
// export const FONT_SHADOW_Y = 'FONT_SHADOW_Y';
// export const FONT_SHADOW_BLUR = 'FONT_SHADOW_BLUR';
export const FONT_STROKE_COLOR = 'FONT_STROKE_COLOR';
export const THICKNESS_SLIDER_VALUE = 'THICKNESS_SLIDER_VALUE';
export const FONT_COLOR = 'FONT_COLOR';
export const RESET_DATA = 'RESET_DATA';
export const FILE_IMAGE = 'FILE_IMAGE';
export const SAVE_ALL_BRANDING_DATA = 'SAVE_ALL_BRANDING_DATA';
export const SAVE_ALL_BRANDING_DATA_SUCCESS = 'SAVE_ALL_BRANDING_DATA_SUCCESS';
export const FETCH_ALL_BRANDING_DATA = 'FETCH_ALL_BRANDING_DATA';
export const FETCH_ALL_BRANDING_DATA_SUCCESS = 'FETCH_ALL_BRANDING_DATA_SUCCESS';
export const SET_TAB_TITLE = 'SET_TAB_TITLE';


interface IProps {
    LINE_POSITION: typeof LINE_POSITION;
    CAPTION_POSITION: typeof CAPTION_POSITION;
    FONT_FAMILY: typeof FONT_FAMILY;
    FONT_WEIGHT: typeof FONT_WEIGHT;
    TOGGLE_UNDERLINE: typeof TOGGLE_UNDERLINE;
    TOGGLE_ITALIC: typeof TOGGLE_ITALIC;
    UPPERCASE: typeof UPPERCASE;
    COUNT: typeof COUNT;
    AI_KEYWORD_HEIGHLIGHTER: typeof AI_KEYWORD_HEIGHLIGHTER;
    AI_KEYWORD_STROKE_HEIGHLIGHTER: typeof AI_KEYWORD_STROKE_HEIGHLIGHTER;
    CHANGE_LAYOUT_SETTING: typeof CHANGE_LAYOUT_SETTING;
    // FONT_SHADOW_COLOR: typeof FONT_SHADOW_COLOR;
    // FONT_SHADOW_X: typeof FONT_SHADOW_X;
    // FONT_SHADOW_Y: typeof FONT_SHADOW_Y;
    // FONT_SHADOW_BLUR: typeof FONT_SHADOW_BLUR;
    FONT_STROKE_COLOR: typeof FONT_STROKE_COLOR;
    THICKNESS_SLIDER_VALUE: typeof THICKNESS_SLIDER_VALUE;
    FONT_COLOR: typeof FONT_COLOR;
    RESET_DATA: typeof RESET_DATA;
    FILE_IMAGE: typeof FILE_IMAGE;
    SAVE_ALL_BRANDING_DATA: typeof SAVE_ALL_BRANDING_DATA;
    SAVE_ALL_BRANDING_DATA_SUCCESS: typeof SAVE_ALL_BRANDING_DATA_SUCCESS;
    FETCH_ALL_BRANDING_DATA: typeof FETCH_ALL_BRANDING_DATA;
    FETCH_ALL_BRANDING_DATA_SUCCESS: typeof FETCH_ALL_BRANDING_DATA_SUCCESS;
    SET_TAB_TITLE: typeof SET_TAB_TITLE;
 
}

export type actionType = IProps
