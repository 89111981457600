// render reducer
export const RENDERING_COMPLETED = 'RENDERING_COMPLETED';
export const RENDERING_FAILED = 'RENDERING_FAILED';
export const RENDERING_PROGRESS = 'RENDERING_PROGRESS';

// connection reducer
export const SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED' ;
export const SET_RE_CONNECTING = 'SOCKET_RE_CONNECTING';
export const CONNECTION_TRIES_RESET = 'CONNECTION_TRIES_RESET';

export const TRANSCRIPTION_PROGRESS = 'TRANSCRIPTION_PROGRESS';
export const TRIMMING_PROGRESS = 'TRIMMING_PROGRESS';
export const REFRAMING_PROGRESS = 'REFRAMING_PROGRESS';

export const CLIPPING_PROGRESS = 'CLIPPING_PROGRESS';

export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS';