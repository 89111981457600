const envJson = require(`../utils/environment.${process.env.REACT_APP_ENV}.json`);

export const config = {
    apiUrl: envJson.apiUrl,
    apiGateway: envJson.apiGateway,
    appUrl: envJson.appUrl,
    redirect: envJson.redirect,
    iFrameUrl: envJson.iFrameUrl,
    addContentCTA: envJson.addContentCTA,
    editInStudioCTA: envJson.editInStudioCTA,
    getSignedUrl: envJson.getSignedUrl,
    ustCookieDomain: envJson.ustCookieDomain,
    ustPoolId: envJson.ustPoolId,
    ustClientId: envJson.ustClientId,
    ustRedirectLink: envJson.ustRedirectLink,
    ustRedirectAccount: envJson.ustRedirectAccount,
    previewLink: envJson.previewLink,
    studioServer: envJson.studioServer,
    BUCKET_S3_DESTINATION: envJson.BUCKET_S3_DESTINATION,
    REGION: envJson.REGION,
    DESTINATION_MEDIA_CDN: envJson.DESTINATION_MEDIA_CDN,
    VUMU_API_GATEWAY: envJson.VUMU_API_GATEWAY,
    UST_API_GATEWAY: envJson.UST_API_GATEWAY,
    CRISP_WEBSITE_ID: envJson.CRISP_WEBSITE_ID,
    EXTENSION_URL: envJson.EXTENSION_URL,
    GA_WEBSITE_ID: envJson.GA_WEBSITE_ID,
    TC_JS_PATH: envJson.TC_JS_PATH,
    PRODUCTS: envJson.PRODUCTS,
    B_TOOLS: envJson.B_TOOLS,
    DEFAULT_SETTING_LOGO: envJson.DEFAULT_SETTING_LOGO,
    DEFAULT_SETTING_FAVICON: envJson.DEFAULT_SETTING_FAVICON,
    KEEVI_TOOLS_URL: envJson.KEEVI_TOOLS_URL,
    CLIPS_APP_URL: envJson.CLIPS_APP_URL,
    SOCKET_URL: envJson.SOCKET_URL,
    REALTIME_PROGRESS_WEBHOOK: envJson.REALTIME_PROGRESS_WEBHOOK,
    TRIM_PROGRESS_WEBHOOK: envJson.TRIM_PROGRESS_WEBHOOK,
    FETCH_CREDENTIALS_API: envJson.FETCH_CREDENTIALS_API
}

const UPLOADED_VIDEOS_FOLDER = "uploaded-videos";
const THUMBNAILS_FOLDER = "custom-thumbnails";
const CUSTOM_SETTINGS_FOLDER = "custom-settings";
const ACCOUNT_SETTING_FOLDER = "account-settings";
const APP = envJson.title;
const APP_LOGO = envJson.logoUrl;
const APP_FAVICON = envJson.favIcon;

interface IProps {
    envJson: typeof envJson;
}

export default {
    ...config,
    UPLOADED_VIDEOS_FOLDER,
    THUMBNAILS_FOLDER,
    CUSTOM_SETTINGS_FOLDER,
    ACCOUNT_SETTING_FOLDER,
    APP,
    APP_LOGO,
    APP_FAVICON
}

export type apiType = IProps;
