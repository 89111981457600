import { Reducer } from 'redux';
import { DOWNLOAD_PROGRESS } from '../action_types';
import { ActionType } from '../interface';

interface initalStateProps {
}

const initState: initalStateProps = {}

const downloadReducer: Reducer<any, ActionType> = (state = initState, action: ActionType) => {
  switch (action.type) {
    case DOWNLOAD_PROGRESS: {
      if (action.payload) {
        state['projectDetails'] = { ...action.payload }
      } else {
        state['projectDetails'] = null;
      }

      return { ...state };
    }

    default:
      return state;
  }
}

export default downloadReducer