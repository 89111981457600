import { SET_AWS_CREDENTIALS } from './types';
import { Reducer } from "redux";
import { ActionType } from '../interface';

interface initalStateProps {}
const initState: initalStateProps = {}

const authReducer: Reducer<initalStateProps, ActionType> = (state = initState, action) => {
  switch (action.type) {
    case SET_AWS_CREDENTIALS:
      return Object.assign({}, state, action.payload)

    default:
      return state;
  }
}

export default authReducer;
