import { SagaIterator } from 'redux-saga';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { GET_AWS_CREDENTIALS_ACTION, SET_AWS_CREDENTIALS } from './types';
import { callApiGatewayWithToken } from "../../helper/api.helper";

  
export function* getAWSCredentails() {
  yield takeEvery(GET_AWS_CREDENTIALS_ACTION, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      
      const response = yield getAWSCredentialsApi();
      resolve(response);
      
      yield put({
        type: SET_AWS_CREDENTIALS,
        payload: response
      });
    } catch (error) {
        reject(error);
        console.error(error);
    }
  });
}

async function getAWSCredentialsApi() {
    return await callApiGatewayWithToken({ url: '/c/get-credentials', payload: {}, method: 'GET' })
}

export default function* rootSaga() {
  yield all([
    fork(getAWSCredentails)
  ]);
}
