import * as types from './types';

export const LinePositionAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.LINE_POSITION, payload });
}
export const CaptionPositionAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.CAPTION_POSITION, payload });
}
export const FontFamilyAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.FONT_FAMILY, payload });
}
export const FontWeightAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.FONT_WEIGHT, payload });
}
export const ToggleUnderlineAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.TOGGLE_UNDERLINE, payload });
}
export const ToggleItalicAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.TOGGLE_ITALIC, payload });
}
export const UppercaseAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.UPPERCASE, payload });
}
export const CountAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.COUNT, payload });
}
export const AiKeywordHighlighterAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.AI_KEYWORD_HEIGHLIGHTER, payload });
}
export const AiKeywordStrokeHighlighterAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.AI_KEYWORD_STROKE_HEIGHLIGHTER, payload });
}
export const ChangeLayoutSettingAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.CHANGE_LAYOUT_SETTING, payload });
}
// export const FontShadowColorAction = (payload: any) => (dispatch: any) => {
//     dispatch({ type: types.FONT_SHADOW_COLOR, payload });
// }
// export const FontShadowXAction = (payload: any) => (dispatch: any) => {
//     dispatch({ type: types.FONT_SHADOW_X, payload });
// }
// export const FontShadowYAction = (payload: any) => (dispatch: any) => {
//     dispatch({ type: types.FONT_SHADOW_Y, payload });
// }
// export const FontShadowBlurAction = (payload: any) => (dispatch: any) => {
//     dispatch({ type: types.FONT_SHADOW_BLUR, payload });
// }
export const FontStrokeColorAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.FONT_STROKE_COLOR, payload });
}
export const ThicknessSliderValueAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.THICKNESS_SLIDER_VALUE, payload });
}
export const FontColorAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.FONT_COLOR, payload });
}
export const ResetAction = (payload: any) => (dispatch: any) => {
    dispatch({ type: types.RESET_DATA, payload });
}
export const FileImageAction = ({payload}:any) => (dispatch: any) => {
    dispatch({ type: types.FILE_IMAGE, payload });
}
export const SaveAllBrandingDataAction = ({payload}: any) => (dispatch: any) => {
    dispatch({ type: types.SAVE_ALL_BRANDING_DATA, payload });
}
export const FetchAllBrandingDataAction = ({payload}: any) => (dispatch: any) => {
    dispatch({ type: types.FETCH_ALL_BRANDING_DATA, payload });
}