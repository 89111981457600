import { Reducer } from 'redux';
import { RENDERING_PROGRESS, RENDERING_COMPLETED, RENDERING_FAILED } from '../action_types';
import { ActionType } from '../interface';
import { RootState } from '../store';
import { IRenderCompletePayload, IRenderFailedPayload, IRenderProcessPayload } from '../../types';
import _cloneDeep from 'lodash/cloneDeep'
interface initalStateProps {
  renderList: IRenderProcessPayload[];
}

const initState: initalStateProps = {
  renderList: []
}

const renderReducer: Reducer<initalStateProps, ActionType> = (state = initState, action: ActionType) => {
  switch (action.type) {
    case RENDERING_PROGRESS: {
      const { progress_payload, video_id } = action.payload as IRenderProcessPayload
      const isVideoExist = state.renderList.some(task => task.video_id === video_id);

      if (!isVideoExist) return { ...state, renderList: state.renderList.concat(action.payload)}

      return {
        ...state,
        renderList: state.renderList.map(item => {
          if (item.video_id === video_id) {
            return {
              ..._cloneDeep(item),
              progress_payload: { ...progress_payload },
            };
          }
          return item;
        }),
      };
    }

    case RENDERING_COMPLETED: {
      const { payload } = action.payload as IRenderCompletePayload;
      return {
        ...state,
        renderList: state.renderList.filter(task => task.video_id === payload.video_id),
      }
    }

    case RENDERING_FAILED: {
      const { payload, type } = action.payload as IRenderFailedPayload;
      const isVideoExist = state.renderList.some(task => task.video_id === payload.video_id);
      const renderFailPayload = { type, ...payload };

      if (!isVideoExist) return {
        ...state,
        renderList: state.renderList.concat({
          ...renderFailPayload,
          progress_payload: {
            current_step: 1,
            percentage: 75,
            step_message: '',
            total_steps: 2,
          },
        }),
      };

      return {
        ...state,
        renderList: state.renderList.map(item => {
          if (item.video_id === payload.video_id) {
            return {
              ..._cloneDeep(item),
              ...renderFailPayload
            };
          }
          return item;
        }),
      };
    }

    default:
      return state;
  }
}

export const getRenderingList = (folderId: string) => (state: RootState) =>
  state.renderReducer.renderList.filter(
    task => task.parentDirectory === folderId,
  );

export const getRenderingTask = (videoId: string) => (state: RootState) =>
  state.renderReducer.renderList.find(
    task => task.video_id === videoId,
  ) || null;

export default renderReducer