import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import * as types from './types';
import {callApiGateway} from '../../helper/api.helper';

export function* SaveAllBrandingDataSaga() {
  yield takeEvery(types.SAVE_ALL_BRANDING_DATA, function* ({payload}: any): SagaIterator {
    try {
        const response = yield call(SaveAllBrandingDataApiCall, payload);
        message.destroy();
    } catch (error) {
        message.error("Error updating Branding");
    }
  });
}
export function* FetchAllBrandingDataSaga() {
  yield takeEvery(types.FETCH_ALL_BRANDING_DATA, function* ({payload}: any): SagaIterator {
    try {
        const response = yield call(FetchAllBrandingDataApiCall, payload);
        const metadataId= response?.templatesGroup.find((item:any) => item.ratio === 'vertical').metadataId;
        const getMetaData = yield call(FetchMetaDataApiCall, metadataId);
        yield put({
          type: types.SAVE_ALL_BRANDING_DATA_SUCCESS,
          payload: getMetaData?.metaData
        });
        message.destroy();
    } catch (error) {
      console.log({error},"error brancding")
        // message.error("Error updating Branding");
    }
  });
}

  async function SaveAllBrandingDataApiCall(payload: any) {
    return await callApiGateway({ url: '/templates/create-group', payload, method: 'POST' })
  }
  async function FetchAllBrandingDataApiCall(payload: any) {
    return await callApiGateway({ url: `/templates/group/${payload}`, payload:{}, method: 'GET' })
  }
  async function FetchMetaDataApiCall(metadataId: any) {
    return await callApiGateway({ url: `/metadata/${metadataId}`, payload:{}, method: 'GET' })
  }
  

export default function* brandingSaga() {
    yield all([
      fork(SaveAllBrandingDataSaga),
      fork(FetchAllBrandingDataSaga),

    ]);
  }
  