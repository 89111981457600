export const AUDIO = "AUDIO"
export const VIDEO = "Video"
export const TEXT = "Text"
export const GIF = "Gif"
export const EMOJI = "Emoji"
export const IMAGE = "Image"
export const SUBS = "Subs"
export const PAGINATION_LIMIT = 50;
export const PROJECT = 'PROJECT';
export const DIRECTORY = 'DIRECTORY';
export const CURRENCY_JSON = [
      { "cc": "AED", "symbol": "\u062f.\u0625;", "name": "UAE dirham" },
      { "cc": "AFN", "symbol": "Afs", "name": "Afghan afghani" },
      { "cc": "ALL", "symbol": "L", "name": "Albanian lek" },
      { "cc": "AMD", "symbol": "AMD", "name": "Armenian dram" },
      { "cc": "ANG", "symbol": "NA\u0192", "name": "Netherlands Antillean gulden" },
      { "cc": "AOA", "symbol": "Kz", "name": "Angolan kwanza" },
      { "cc": "ARS", "symbol": "$", "name": "Argentine peso" },
      { "cc": "AUD", "symbol": "$", "name": "Australian dollar" },
      { "cc": "AWG", "symbol": "\u0192", "name": "Aruban florin" },
      { "cc": "AZN", "symbol": "AZN", "name": "Azerbaijani manat" },
      { "cc": "BAM", "symbol": "KM", "name": "Bosnia and Herzegovina konvertibilna marka" },
      { "cc": "BBD", "symbol": "Bds$", "name": "Barbadian dollar" },
      { "cc": "BDT", "symbol": "\u09f3", "name": "Bangladeshi taka" },
      { "cc": "BGN", "symbol": "BGN", "name": "Bulgarian lev" },
      { "cc": "BHD", "symbol": ".\u062f.\u0628", "name": "Bahraini dinar" },
      { "cc": "BIF", "symbol": "FBu", "name": "Burundi franc" },
      { "cc": "BMD", "symbol": "BD$", "name": "Bermudian dollar" },
      { "cc": "BND", "symbol": "B$", "name": "Brunei dollar" },
      { "cc": "BOB", "symbol": "Bs.", "name": "Bolivian boliviano" },
      { "cc": "BRL", "symbol": "R$", "name": "Brazilian real" },
      { "cc": "BSD", "symbol": "B$", "name": "Bahamian dollar" },
      { "cc": "BTN", "symbol": "Nu.", "name": "Bhutanese ngultrum" },
      { "cc": "BWP", "symbol": "P", "name": "Botswana pula" },
      { "cc": "BYR", "symbol": "Br", "name": "Belarusian ruble" },
      { "cc": "BZD", "symbol": "BZ$", "name": "Belize dollar" },
      { "cc": "CAD", "symbol": "$", "name": "Canadian dollar" },
      { "cc": "CDF", "symbol": "F", "name": "Congolese franc" },
      { "cc": "CHF", "symbol": "Fr.", "name": "Swiss franc" },
      { "cc": "CLP", "symbol": "$", "name": "Chilean peso" },
      { "cc": "CNY", "symbol": "\u00a5", "name": "Chinese/Yuan renminbi" },
      { "cc": "COP", "symbol": "Col$", "name": "Colombian peso" },
      { "cc": "CRC", "symbol": "\u20a1", "name": "Costa Rican colon" },
      { "cc": "CUC", "symbol": "$", "name": "Cuban peso" },
      { "cc": "CVE", "symbol": "Esc", "name": "Cape Verdean escudo" },
      { "cc": "CZK", "symbol": "K\u010d", "name": "Czech koruna" },
      { "cc": "DJF", "symbol": "Fdj", "name": "Djiboutian franc" },
      { "cc": "DKK", "symbol": "Kr", "name": "Danish krone" },
      { "cc": "DOP", "symbol": "RD$", "name": "Dominican peso" },
      { "cc": "DZD", "symbol": "\u062f.\u062c", "name": "Algerian dinar" },
      { "cc": "EEK", "symbol": "KR", "name": "Estonian kroon" },
      { "cc": "EGP", "symbol": "\u00a3", "name": "Egyptian pound" },
      { "cc": "ERN", "symbol": "Nfa", "name": "Eritrean nakfa" },
      { "cc": "ETB", "symbol": "Br", "name": "Ethiopian birr" },
      { "cc": "EUR", "symbol": "\u20ac", "name": "European Euro" },
      { "cc": "FJD", "symbol": "FJ$", "name": "Fijian dollar" },
      { "cc": "FKP", "symbol": "\u00a3", "name": "Falkland Islands pound" },
      { "cc": "GBP", "symbol": "\u00a3", "name": "British pound" },
      { "cc": "GEL", "symbol": "GEL", "name": "Georgian lari" },
      { "cc": "GHS", "symbol": "GH\u20b5", "name": "Ghanaian cedi" },
      { "cc": "GIP", "symbol": "\u00a3", "name": "Gibraltar pound" },
      { "cc": "GMD", "symbol": "D", "name": "Gambian dalasi" },
      { "cc": "GNF", "symbol": "FG", "name": "Guinean franc" },
      { "cc": "GQE", "symbol": "CFA", "name": "Central African CFA franc" },
      { "cc": "GTQ", "symbol": "Q", "name": "Guatemalan quetzal" },
      { "cc": "GYD", "symbol": "GY$", "name": "Guyanese dollar" },
      { "cc": "HKD", "symbol": "HK$", "name": "Hong Kong dollar" },
      { "cc": "HNL", "symbol": "L", "name": "Honduran lempira" },
      { "cc": "HRK", "symbol": "kn", "name": "Croatian kuna" },
      { "cc": "HTG", "symbol": "G", "name": "Haitian gourde" },
      { "cc": "HUF", "symbol": "Ft", "name": "Hungarian forint" },
      { "cc": "IDR", "symbol": "Rp", "name": "Indonesian rupiah" },
      { "cc": "ILS", "symbol": "\u20aa", "name": "Israeli new sheqel" },
      { "cc": "INR", "symbol": "\u20B9", "name": "Indian rupee" },
      { "cc": "IQD", "symbol": "\u062f.\u0639", "name": "Iraqi dinar" },
      { "cc": "IRR", "symbol": "IRR", "name": "Iranian rial" },
      { "cc": "ISK", "symbol": "kr", "name": "Icelandic kr\u00f3na" },
      { "cc": "JMD", "symbol": "J$", "name": "Jamaican dollar" },
      { "cc": "JOD", "symbol": "JOD", "name": "Jordanian dinar" },
      { "cc": "JPY", "symbol": "\u00a5", "name": "Japanese yen" },
      { "cc": "KES", "symbol": "KSh", "name": "Kenyan shilling" },
      { "cc": "KGS", "symbol": "\u0441\u043e\u043c", "name": "Kyrgyzstani som" },
      { "cc": "KHR", "symbol": "\u17db", "name": "Cambodian riel" },
      { "cc": "KMF", "symbol": "KMF", "name": "Comorian franc" },
      { "cc": "KPW", "symbol": "W", "name": "North Korean won" },
      { "cc": "KRW", "symbol": "W", "name": "South Korean won" },
      { "cc": "KWD", "symbol": "KWD", "name": "Kuwaiti dinar" },
      { "cc": "KYD", "symbol": "KY$", "name": "Cayman Islands dollar" },
      { "cc": "KZT", "symbol": "T", "name": "Kazakhstani tenge" },
      { "cc": "LAK", "symbol": "KN", "name": "Lao kip" },
      { "cc": "LBP", "symbol": "\u00a3", "name": "Lebanese lira" },
      { "cc": "LKR", "symbol": "Rs", "name": "Sri Lankan rupee" },
      { "cc": "LRD", "symbol": "L$", "name": "Liberian dollar" },
      { "cc": "LSL", "symbol": "M", "name": "Lesotho loti" },
      { "cc": "LTL", "symbol": "Lt", "name": "Lithuanian litas" },
      { "cc": "LVL", "symbol": "Ls", "name": "Latvian lats" },
      { "cc": "LYD", "symbol": "LD", "name": "Libyan dinar" },
      { "cc": "MAD", "symbol": "MAD", "name": "Moroccan dirham" },
      { "cc": "MDL", "symbol": "MDL", "name": "Moldovan leu" },
      { "cc": "MGA", "symbol": "FMG", "name": "Malagasy ariary" },
      { "cc": "MKD", "symbol": "MKD", "name": "Macedonian denar" },
      { "cc": "MMK", "symbol": "K", "name": "Myanma kyat" },
      { "cc": "MNT", "symbol": "\u20ae", "name": "Mongolian tugrik" },
      { "cc": "MOP", "symbol": "P", "name": "Macanese pataca" },
      { "cc": "MRO", "symbol": "UM", "name": "Mauritanian ouguiya" },
      { "cc": "MUR", "symbol": "Rs", "name": "Mauritian rupee" },
      { "cc": "MVR", "symbol": "Rf", "name": "Maldivian rufiyaa" },
      { "cc": "MWK", "symbol": "MK", "name": "Malawian kwacha" },
      { "cc": "MXN", "symbol": "$", "name": "Mexican peso" },
      { "cc": "MYR", "symbol": "RM", "name": "Malaysian ringgit" },
      { "cc": "MZM", "symbol": "MTn", "name": "Mozambican metical" },
      { "cc": "NAD", "symbol": "N$", "name": "Namibian dollar" },
      { "cc": "NGN", "symbol": "\u20a6", "name": "Nigerian naira" },
      { "cc": "NIO", "symbol": "C$", "name": "Nicaraguan c\u00f3rdoba" },
      { "cc": "NOK", "symbol": "kr", "name": "Norwegian krone" },
      { "cc": "NPR", "symbol": "NRs", "name": "Nepalese rupee" },
      { "cc": "NZD", "symbol": "NZ$", "name": "New Zealand dollar" },
      { "cc": "OMR", "symbol": "OMR", "name": "Omani rial" },
      { "cc": "PAB", "symbol": "B./", "name": "Panamanian balboa" },
      { "cc": "PEN", "symbol": "S/.", "name": "Peruvian nuevo sol" },
      { "cc": "PGK", "symbol": "K", "name": "Papua New Guinean kina" },
      { "cc": "PHP", "symbol": "\u20b1", "name": "Philippine peso" },
      { "cc": "PKR", "symbol": "Rs.", "name": "Pakistani rupee" },
      { "cc": "PLN", "symbol": "z\u0142", "name": "Polish zloty" },
      { "cc": "PYG", "symbol": "\u20b2", "name": "Paraguayan guarani" },
      { "cc": "QAR", "symbol": "QR", "name": "Qatari riyal" },
      { "cc": "RON", "symbol": "L", "name": "Romanian leu" },
      { "cc": "RSD", "symbol": "din.", "name": "Serbian dinar" },
      { "cc": "RUB", "symbol": "R", "name": "Russian ruble" },
      { "cc": "SAR", "symbol": "SR", "name": "Saudi riyal" },
      { "cc": "SBD", "symbol": "SI$", "name": "Solomon Islands dollar" },
      { "cc": "SCR", "symbol": "SR", "name": "Seychellois rupee" },
      { "cc": "SDG", "symbol": "SDG", "name": "Sudanese pound" },
      { "cc": "SEK", "symbol": "kr", "name": "Swedish krona" },
      { "cc": "SGD", "symbol": "S$", "name": "Singapore dollar" },
      { "cc": "SHP", "symbol": "\u00a3", "name": "Saint Helena pound" },
      { "cc": "SLL", "symbol": "Le", "name": "Sierra Leonean leone" },
      { "cc": "SOS", "symbol": "Sh.", "name": "Somali shilling" },
      { "cc": "SRD", "symbol": "$", "name": "Surinamese dollar" },
      { "cc": "SYP", "symbol": "LS", "name": "Syrian pound" },
      { "cc": "SZL", "symbol": "E", "name": "Swazi lilangeni" },
      { "cc": "THB", "symbol": "\u0e3f", "name": "Thai baht" },
      { "cc": "TJS", "symbol": "TJS", "name": "Tajikistani somoni" },
      { "cc": "TMT", "symbol": "m", "name": "Turkmen manat" },
      { "cc": "TND", "symbol": "DT", "name": "Tunisian dinar" },
      { "cc": "TRY", "symbol": "TRY", "name": "Turkish new lira" },
      { "cc": "TTD", "symbol": "TT$", "name": "Trinidad and Tobago dollar" },
      { "cc": "TWD", "symbol": "NT$", "name": "New Taiwan dollar" },
      { "cc": "TZS", "symbol": "TZS", "name": "Tanzanian shilling" },
      { "cc": "UAH", "symbol": "UAH", "name": "Ukrainian hryvnia" },
      { "cc": "UGX", "symbol": "USh", "name": "Ugandan shilling" },
      { "cc": "USD", "symbol": "US$", "name": "United States dollar" },
      { "cc": "UYU", "symbol": "$U", "name": "Uruguayan peso" },
      { "cc": "UZS", "symbol": "UZS", "name": "Uzbekistani som" },
      { "cc": "VEB", "symbol": "Bs", "name": "Venezuelan bolivar" },
      { "cc": "VND", "symbol": "\u20ab", "name": "Vietnamese dong" },
      { "cc": "VUV", "symbol": "VT", "name": "Vanuatu vatu" },
      { "cc": "WST", "symbol": "WS$", "name": "Samoan tala" },
      { "cc": "XAF", "symbol": "CFA", "name": "Central African CFA franc" },
      { "cc": "XCD", "symbol": "EC$", "name": "East Caribbean dollar" },
      { "cc": "XDR", "symbol": "SDR", "name": "Special Drawing Rights" },
      { "cc": "XOF", "symbol": "CFA", "name": "West African CFA franc" },
      { "cc": "XPF", "symbol": "F", "name": "CFP franc" },
      { "cc": "YER", "symbol": "YER", "name": "Yemeni rial" },
      { "cc": "ZAR", "symbol": "R", "name": "South African rand" },
      { "cc": "ZMK", "symbol": "ZK", "name": "Zambian kwacha" },
      { "cc": "ZWR", "symbol": "Z$", "name": "Zimbabwean dollar" }
]

interface IProps {
      VIDEO: typeof VIDEO;
      AUDIO: typeof AUDIO;
      SUBS: typeof SUBS;
      TEXT: typeof TEXT;
      IMAGE: typeof IMAGE;
      GIF: typeof GIF;
      EMOJI: typeof EMOJI;
      PAGINATION_LIMIT: typeof PAGINATION_LIMIT;
      PROJECT: typeof PROJECT;
      DIRECTORY: typeof DIRECTORY;
      CURRENCY_JSON: typeof CURRENCY_JSON;
}

export type actionType = IProps;

export const TIME_ZONE = [
      {
            "value": "Dateline Standard Time",
            "abbr": "DST",
            "offset": -12,
            "isdst": false,
            "text": "(UTC-12:00) International Date Line West",
            "utc": [
                  "Etc/GMT+12"
            ]
      },
      {
            "value": "UTC-11",
            "abbr": "U",
            "offset": -11,
            "isdst": false,
            "text": "(UTC-11:00) Coordinated Universal Time-11",
            "utc": [
                  "Etc/GMT+11",
                  "Pacific/Midway",
                  "Pacific/Niue",
                  "Pacific/Pago_Pago"
            ]
      },
      {
            "value": "Hawaiian Standard Time",
            "abbr": "HST",
            "offset": -10,
            "isdst": false,
            "text": "(UTC-10:00) Hawaii",
            "utc": [
                  "Etc/GMT+10",
                  "Pacific/Honolulu",
                  "Pacific/Johnston",
                  "Pacific/Rarotonga",
                  "Pacific/Tahiti"
            ]
      },
      {
            "value": "Alaskan Standard Time",
            "abbr": "AKDT",
            "offset": -8,
            "isdst": true,
            "text": "(UTC-09:00) Alaska",
            "utc": [
                  "America/Anchorage",
                  "America/Juneau",
                  "America/Nome",
                  "America/Sitka",
                  "America/Yakutat"
            ]
      },
      {
            "value": "Pacific Standard Time (Mexico)",
            "abbr": "PDT",
            "offset": -7,
            "isdst": true,
            "text": "(UTC-08:00) Baja California",
            "utc": [
                  "America/Santa_Isabel"
            ]
      },
      {
            "value": "Pacific Daylight Time",
            "abbr": "PDT",
            "offset": -7,
            "isdst": true,
            "text": "(UTC-07:00) Pacific Time (US & Canada)",
            "utc": [
                  "America/Dawson",
                  "America/Los_Angeles",
                  "America/Tijuana",
                  "America/Vancouver",
                  "America/Whitehorse"
            ]
      },
      {
            "value": "Pacific Standard Time",
            "abbr": "PST",
            "offset": -8,
            "isdst": false,
            "text": "(UTC-08:00) Pacific Time (US & Canada)",
            "utc": [
                  "America/Dawson",
                  "America/Los_Angeles",
                  "America/Tijuana",
                  "America/Vancouver",
                  "America/Whitehorse",
                  "PST8PDT"
            ]
      },
      {
            "value": "US Mountain Standard Time",
            "abbr": "UMST",
            "offset": -7,
            "isdst": false,
            "text": "(UTC-07:00) Arizona",
            "utc": [
                  "America/Creston",
                  "America/Dawson_Creek",
                  "America/Hermosillo",
                  "America/Phoenix",
                  "Etc/GMT+7"
            ]
      },
      {
            "value": "Mountain Standard Time (Mexico)",
            "abbr": "MDT",
            "offset": -6,
            "isdst": true,
            "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
            "utc": [
                  "America/Chihuahua",
                  "America/Mazatlan"
            ]
      },
      {
            "value": "Mountain Standard Time",
            "abbr": "MDT",
            "offset": -6,
            "isdst": true,
            "text": "(UTC-07:00) Mountain Time (US & Canada)",
            "utc": [
                  "America/Boise",
                  "America/Cambridge_Bay",
                  "America/Denver",
                  "America/Edmonton",
                  "America/Inuvik",
                  "America/Ojinaga",
                  "America/Yellowknife",
                  "MST7MDT"
            ]
      },
      {
            "value": "Central America Standard Time",
            "abbr": "CAST",
            "offset": -6,
            "isdst": false,
            "text": "(UTC-06:00) Central America",
            "utc": [
                  "America/Belize",
                  "America/Costa_Rica",
                  "America/El_Salvador",
                  "America/Guatemala",
                  "America/Managua",
                  "America/Tegucigalpa",
                  "Etc/GMT+6",
                  "Pacific/Galapagos"
            ]
      },
      {
            "value": "Central Standard Time",
            "abbr": "CDT",
            "offset": -5,
            "isdst": true,
            "text": "(UTC-06:00) Central Time (US & Canada)",
            "utc": [
                  "America/Chicago",
                  "America/Indiana/Knox",
                  "America/Indiana/Tell_City",
                  "America/Matamoros",
                  "America/Menominee",
                  "America/North_Dakota/Beulah",
                  "America/North_Dakota/Center",
                  "America/North_Dakota/New_Salem",
                  "America/Rainy_River",
                  "America/Rankin_Inlet",
                  "America/Resolute",
                  "America/Winnipeg",
                  "CST6CDT"
            ]
      },
      {
            "value": "Central Standard Time (Mexico)",
            "abbr": "CDT",
            "offset": -5,
            "isdst": true,
            "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
            "utc": [
                  "America/Bahia_Banderas",
                  "America/Cancun",
                  "America/Merida",
                  "America/Mexico_City",
                  "America/Monterrey"
            ]
      },
      {
            "value": "Canada Central Standard Time",
            "abbr": "CCST",
            "offset": -6,
            "isdst": false,
            "text": "(UTC-06:00) Saskatchewan",
            "utc": [
                  "America/Regina",
                  "America/Swift_Current"
            ]
      },
      {
            "value": "SA Pacific Standard Time",
            "abbr": "SPST",
            "offset": -5,
            "isdst": false,
            "text": "(UTC-05:00) Bogota, Lima, Quito",
            "utc": [
                  "America/Bogota",
                  "America/Cayman",
                  "America/Coral_Harbour",
                  "America/Eirunepe",
                  "America/Guayaquil",
                  "America/Jamaica",
                  "America/Lima",
                  "America/Panama",
                  "America/Rio_Branco",
                  "Etc/GMT+5"
            ]
      },
      {
            "value": "Eastern Standard Time",
            "abbr": "EDT",
            "offset": -4,
            "isdst": true,
            "text": "(UTC-05:00) Eastern Time (US & Canada)",
            "utc": [
                  "America/Detroit",
                  "America/Havana",
                  "America/Indiana/Petersburg",
                  "America/Indiana/Vincennes",
                  "America/Indiana/Winamac",
                  "America/Iqaluit",
                  "America/Kentucky/Monticello",
                  "America/Louisville",
                  "America/Montreal",
                  "America/Nassau",
                  "America/New_York",
                  "America/Nipigon",
                  "America/Pangnirtung",
                  "America/Port-au-Prince",
                  "America/Thunder_Bay",
                  "America/Toronto",
                  "EST5EDT"
            ]
      },
      {
            "value": "US Eastern Standard Time",
            "abbr": "UEDT",
            "offset": -4,
            "isdst": true,
            "text": "(UTC-05:00) Indiana (East)",
            "utc": [
                  "America/Indiana/Marengo",
                  "America/Indiana/Vevay",
                  "America/Indianapolis"
            ]
      },
      {
            "value": "Venezuela Standard Time",
            "abbr": "VST",
            "offset": -4.5,
            "isdst": false,
            "text": "(UTC-04:30) Caracas",
            "utc": [
                  "America/Caracas"
            ]
      },
      {
            "value": "Paraguay Standard Time",
            "abbr": "PYT",
            "offset": -4,
            "isdst": false,
            "text": "(UTC-04:00) Asuncion",
            "utc": [
                  "America/Asuncion"
            ]
      },
      {
            "value": "Atlantic Standard Time",
            "abbr": "ADT",
            "offset": -3,
            "isdst": true,
            "text": "(UTC-04:00) Atlantic Time (Canada)",
            "utc": [
                  "America/Glace_Bay",
                  "America/Goose_Bay",
                  "America/Halifax",
                  "America/Moncton",
                  "America/Thule",
                  "Atlantic/Bermuda"
            ]
      },
      {
            "value": "Central Brazilian Standard Time",
            "abbr": "CBST",
            "offset": -4,
            "isdst": false,
            "text": "(UTC-04:00) Cuiaba",
            "utc": [
                  "America/Campo_Grande",
                  "America/Cuiaba"
            ]
      },
      {
            "value": "SA Western Standard Time",
            "abbr": "SWST",
            "offset": -4,
            "isdst": false,
            "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
            "utc": [
                  "America/Anguilla",
                  "America/Antigua",
                  "America/Aruba",
                  "America/Barbados",
                  "America/Blanc-Sablon",
                  "America/Boa_Vista",
                  "America/Curacao",
                  "America/Dominica",
                  "America/Grand_Turk",
                  "America/Grenada",
                  "America/Guadeloupe",
                  "America/Guyana",
                  "America/Kralendijk",
                  "America/La_Paz",
                  "America/Lower_Princes",
                  "America/Manaus",
                  "America/Marigot",
                  "America/Martinique",
                  "America/Montserrat",
                  "America/Port_of_Spain",
                  "America/Porto_Velho",
                  "America/Puerto_Rico",
                  "America/Santo_Domingo",
                  "America/St_Barthelemy",
                  "America/St_Kitts",
                  "America/St_Lucia",
                  "America/St_Thomas",
                  "America/St_Vincent",
                  "America/Tortola",
                  "Etc/GMT+4"
            ]
      },
      {
            "value": "Pacific SA Standard Time",
            "abbr": "PSST",
            "offset": -4,
            "isdst": false,
            "text": "(UTC-04:00) Santiago",
            "utc": [
                  "America/Santiago",
                  "Antarctica/Palmer"
            ]
      },
      {
            "value": "Newfoundland Standard Time",
            "abbr": "NDT",
            "offset": -2.5,
            "isdst": true,
            "text": "(UTC-03:30) Newfoundland",
            "utc": [
                  "America/St_Johns"
            ]
      },
      {
            "value": "E. South America Standard Time",
            "abbr": "ESAST",
            "offset": -3,
            "isdst": false,
            "text": "(UTC-03:00) Brasilia",
            "utc": [
                  "America/Sao_Paulo"
            ]
      },
      {
            "value": "Argentina Standard Time",
            "abbr": "AST",
            "offset": -3,
            "isdst": false,
            "text": "(UTC-03:00) Buenos Aires",
            "utc": [
                  "America/Argentina/La_Rioja",
                  "America/Argentina/Rio_Gallegos",
                  "America/Argentina/Salta",
                  "America/Argentina/San_Juan",
                  "America/Argentina/San_Luis",
                  "America/Argentina/Tucuman",
                  "America/Argentina/Ushuaia",
                  "America/Buenos_Aires",
                  "America/Catamarca",
                  "America/Cordoba",
                  "America/Jujuy",
                  "America/Mendoza"
            ]
      },
      {
            "value": "SA Eastern Standard Time",
            "abbr": "SEST",
            "offset": -3,
            "isdst": false,
            "text": "(UTC-03:00) Cayenne, Fortaleza",
            "utc": [
                  "America/Araguaina",
                  "America/Belem",
                  "America/Cayenne",
                  "America/Fortaleza",
                  "America/Maceio",
                  "America/Paramaribo",
                  "America/Recife",
                  "America/Santarem",
                  "Antarctica/Rothera",
                  "Atlantic/Stanley",
                  "Etc/GMT+3"
            ]
      },
      {
            "value": "Greenland Standard Time",
            "abbr": "GDT",
            "offset": -3,
            "isdst": true,
            "text": "(UTC-03:00) Greenland",
            "utc": [
                  "America/Godthab"
            ]
      },
      {
            "value": "Montevideo Standard Time",
            "abbr": "MST",
            "offset": -3,
            "isdst": false,
            "text": "(UTC-03:00) Montevideo",
            "utc": [
                  "America/Montevideo"
            ]
      },
      {
            "value": "Bahia Standard Time",
            "abbr": "BST",
            "offset": -3,
            "isdst": false,
            "text": "(UTC-03:00) Salvador",
            "utc": [
                  "America/Bahia"
            ]
      },
      {
            "value": "UTC-02",
            "abbr": "U",
            "offset": -2,
            "isdst": false,
            "text": "(UTC-02:00) Coordinated Universal Time-02",
            "utc": [
                  "America/Noronha",
                  "Atlantic/South_Georgia",
                  "Etc/GMT+2"
            ]
      },
      {
            "value": "Mid-Atlantic Standard Time",
            "abbr": "MDT",
            "offset": -1,
            "isdst": true,
            "text": "(UTC-02:00) Mid-Atlantic - Old",
            "utc": []
      },
      {
            "value": "Azores Standard Time",
            "abbr": "ADT",
            "offset": 0,
            "isdst": true,
            "text": "(UTC-01:00) Azores",
            "utc": [
                  "America/Scoresbysund",
                  "Atlantic/Azores"
            ]
      },
      {
            "value": "Cape Verde Standard Time",
            "abbr": "CVST",
            "offset": -1,
            "isdst": false,
            "text": "(UTC-01:00) Cape Verde Is.",
            "utc": [
                  "Atlantic/Cape_Verde",
                  "Etc/GMT+1"
            ]
      },
      {
            "value": "Morocco Standard Time",
            "abbr": "MDT",
            "offset": 1,
            "isdst": true,
            "text": "(UTC) Casablanca",
            "utc": [
                  "Africa/Casablanca",
                  "Africa/El_Aaiun"
            ]
      },
      {
            "value": "UTC",
            "abbr": "UTC",
            "offset": 0,
            "isdst": false,
            "text": "(UTC) Coordinated Universal Time",
            "utc": [
                  "America/Danmarkshavn",
                  "Etc/GMT"
            ]
      },
      {
            "value": "GMT Standard Time UK",
            "abbr": "GMT",
            "offset": 0,
            "isdst": false,
            "text": "(UTC) Edinburgh, London",
            "utc": [
                  "Europe/Isle_of_Man",
                  "Europe/Guernsey",
                  "Europe/Jersey",
                  "Europe/London"
            ]
      },
      {
            "value": "British Summer Time",
            "abbr": "BST",
            "offset": 1,
            "isdst": true,
            "text": "(UTC+01:00) Edinburgh, London",
            "utc": [
                  "Europe/Isle_of_Man",
                  "Europe/Guernsey",
                  "Europe/Jersey",
                  "Europe/London"
            ]
      },
      {
            "value": "GMT Standard Time",
            "abbr": "GDT",
            "offset": 1,
            "isdst": true,
            "text": "(UTC) Dublin, Lisbon",
            "utc": [
                  "Atlantic/Canary",
                  "Atlantic/Faeroe",
                  "Atlantic/Madeira",
                  "Europe/Dublin",
                  "Europe/Lisbon"
            ]
      },
      {
            "value": "Greenwich Standard Time",
            "abbr": "GST",
            "offset": 0,
            "isdst": false,
            "text": "(UTC) Monrovia, Reykjavik",
            "utc": [
                  "Africa/Abidjan",
                  "Africa/Accra",
                  "Africa/Bamako",
                  "Africa/Banjul",
                  "Africa/Bissau",
                  "Africa/Conakry",
                  "Africa/Dakar",
                  "Africa/Freetown",
                  "Africa/Lome",
                  "Africa/Monrovia",
                  "Africa/Nouakchott",
                  "Africa/Ouagadougou",
                  "Africa/Sao_Tome",
                  "Atlantic/Reykjavik",
                  "Atlantic/St_Helena"
            ]
      },
      {
            "value": "W. Europe Standard Time",
            "abbr": "WEDT",
            "offset": 2,
            "isdst": true,
            "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
            "utc": [
                  "Arctic/Longyearbyen",
                  "Europe/Amsterdam",
                  "Europe/Andorra",
                  "Europe/Berlin",
                  "Europe/Busingen",
                  "Europe/Gibraltar",
                  "Europe/Luxembourg",
                  "Europe/Malta",
                  "Europe/Monaco",
                  "Europe/Oslo",
                  "Europe/Rome",
                  "Europe/San_Marino",
                  "Europe/Stockholm",
                  "Europe/Vaduz",
                  "Europe/Vatican",
                  "Europe/Vienna",
                  "Europe/Zurich"
            ]
      },
      {
            "value": "Central Europe Standard Time",
            "abbr": "CEDT",
            "offset": 2,
            "isdst": true,
            "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
            "utc": [
                  "Europe/Belgrade",
                  "Europe/Bratislava",
                  "Europe/Budapest",
                  "Europe/Ljubljana",
                  "Europe/Podgorica",
                  "Europe/Prague",
                  "Europe/Tirane"
            ]
      },
      {
            "value": "Romance Standard Time",
            "abbr": "RDT",
            "offset": 2,
            "isdst": true,
            "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
            "utc": [
                  "Africa/Ceuta",
                  "Europe/Brussels",
                  "Europe/Copenhagen",
                  "Europe/Madrid",
                  "Europe/Paris"
            ]
      },
      {
            "value": "Central European Standard Time",
            "abbr": "CEDT",
            "offset": 2,
            "isdst": true,
            "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
            "utc": [
                  "Europe/Sarajevo",
                  "Europe/Skopje",
                  "Europe/Warsaw",
                  "Europe/Zagreb"
            ]
      },
      {
            "value": "W. Central Africa Standard Time",
            "abbr": "WCAST",
            "offset": 1,
            "isdst": false,
            "text": "(UTC+01:00) West Central Africa",
            "utc": [
                  "Africa/Algiers",
                  "Africa/Bangui",
                  "Africa/Brazzaville",
                  "Africa/Douala",
                  "Africa/Kinshasa",
                  "Africa/Lagos",
                  "Africa/Libreville",
                  "Africa/Luanda",
                  "Africa/Malabo",
                  "Africa/Ndjamena",
                  "Africa/Niamey",
                  "Africa/Porto-Novo",
                  "Africa/Tunis",
                  "Etc/GMT-1"
            ]
      },
      {
            "value": "Namibia Standard Time",
            "abbr": "NST",
            "offset": 1,
            "isdst": false,
            "text": "(UTC+01:00) Windhoek",
            "utc": [
                  "Africa/Windhoek"
            ]
      },
      {
            "value": "GTB Standard Time",
            "abbr": "GDT",
            "offset": 3,
            "isdst": true,
            "text": "(UTC+02:00) Athens, Bucharest",
            "utc": [
                  "Asia/Nicosia",
                  "Europe/Athens",
                  "Europe/Bucharest",
                  "Europe/Chisinau"
            ]
      },
      {
            "value": "Middle East Standard Time",
            "abbr": "MEDT",
            "offset": 3,
            "isdst": true,
            "text": "(UTC+02:00) Beirut",
            "utc": [
                  "Asia/Beirut"
            ]
      },
      {
            "value": "Egypt Standard Time",
            "abbr": "EST",
            "offset": 2,
            "isdst": false,
            "text": "(UTC+02:00) Cairo",
            "utc": [
                  "Africa/Cairo"
            ]
      },
      {
            "value": "Syria Standard Time",
            "abbr": "SDT",
            "offset": 3,
            "isdst": true,
            "text": "(UTC+02:00) Damascus",
            "utc": [
                  "Asia/Damascus"
            ]
      },
      {
            "value": "E. Europe Standard Time",
            "abbr": "EEDT",
            "offset": 3,
            "isdst": true,
            "text": "(UTC+02:00) E. Europe",
            "utc": [
                  "Asia/Nicosia",
                  "Europe/Athens",
                  "Europe/Bucharest",
                  "Europe/Chisinau",
                  "Europe/Helsinki",
                  "Europe/Kiev",
                  "Europe/Mariehamn",
                  "Europe/Nicosia",
                  "Europe/Riga",
                  "Europe/Sofia",
                  "Europe/Tallinn",
                  "Europe/Uzhgorod",
                  "Europe/Vilnius",
                  "Europe/Zaporozhye"

            ]
      },
      {
            "value": "South Africa Standard Time",
            "abbr": "SAST",
            "offset": 2,
            "isdst": false,
            "text": "(UTC+02:00) Harare, Pretoria",
            "utc": [
                  "Africa/Blantyre",
                  "Africa/Bujumbura",
                  "Africa/Gaborone",
                  "Africa/Harare",
                  "Africa/Johannesburg",
                  "Africa/Kigali",
                  "Africa/Lubumbashi",
                  "Africa/Lusaka",
                  "Africa/Maputo",
                  "Africa/Maseru",
                  "Africa/Mbabane",
                  "Etc/GMT-2"
            ]
      },
      {
            "value": "FLE Standard Time",
            "abbr": "FDT",
            "offset": 3,
            "isdst": true,
            "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
            "utc": [
                  "Europe/Helsinki",
                  "Europe/Kiev",
                  "Europe/Mariehamn",
                  "Europe/Riga",
                  "Europe/Sofia",
                  "Europe/Tallinn",
                  "Europe/Uzhgorod",
                  "Europe/Vilnius",
                  "Europe/Zaporozhye"
            ]
      },
      {
            "value": "Turkey Standard Time",
            "abbr": "TDT",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+03:00) Istanbul",
            "utc": [
                  "Europe/Istanbul"
            ]
      },
      {
            "value": "Israel Standard Time",
            "abbr": "JDT",
            "offset": 3,
            "isdst": true,
            "text": "(UTC+02:00) Jerusalem",
            "utc": [
                  "Asia/Jerusalem"
            ]
      },
      {
            "value": "Libya Standard Time",
            "abbr": "LST",
            "offset": 2,
            "isdst": false,
            "text": "(UTC+02:00) Tripoli",
            "utc": [
                  "Africa/Tripoli"
            ]
      },
      {
            "value": "Jordan Standard Time",
            "abbr": "JST",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+03:00) Amman",
            "utc": [
                  "Asia/Amman"
            ]
      },
      {
            "value": "Arabic Standard Time",
            "abbr": "AST",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+03:00) Baghdad",
            "utc": [
                  "Asia/Baghdad"
            ]
      },
      {
            "value": "Kaliningrad Standard Time",
            "abbr": "KST",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+02:00) Kaliningrad",
            "utc": [
                  "Europe/Kaliningrad"
            ]
      },
      {
            "value": "Arab Standard Time",
            "abbr": "AST",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+03:00) Kuwait, Riyadh",
            "utc": [
                  "Asia/Aden",
                  "Asia/Bahrain",
                  "Asia/Kuwait",
                  "Asia/Qatar",
                  "Asia/Riyadh"
            ]
      },
      {
            "value": "E. Africa Standard Time",
            "abbr": "EAST",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+03:00) Nairobi",
            "utc": [
                  "Africa/Addis_Ababa",
                  "Africa/Asmera",
                  "Africa/Dar_es_Salaam",
                  "Africa/Djibouti",
                  "Africa/Juba",
                  "Africa/Kampala",
                  "Africa/Khartoum",
                  "Africa/Mogadishu",
                  "Africa/Nairobi",
                  "Antarctica/Syowa",
                  "Etc/GMT-3",
                  "Indian/Antananarivo",
                  "Indian/Comoro",
                  "Indian/Mayotte"
            ]
      },
      {
            "value": "Moscow Standard Time",
            "abbr": "MSK",
            "offset": 3,
            "isdst": false,
            "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
            "utc": [
                  "Europe/Kirov",
                  "Europe/Moscow",
                  "Europe/Simferopol",
                  "Europe/Volgograd",
                  "Europe/Minsk"
            ]
      },
      {
            "value": "Samara Time",
            "abbr": "SAMT",
            "offset": 4,
            "isdst": false,
            "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
            "utc": [
                  "Europe/Astrakhan",
                  "Europe/Samara",
                  "Europe/Ulyanovsk"
            ]
      },
      {
            "value": "Iran Standard Time",
            "abbr": "IDT",
            "offset": 4.5,
            "isdst": true,
            "text": "(UTC+03:30) Tehran",
            "utc": [
                  "Asia/Tehran"
            ]
      },
      {
            "value": "Arabian Standard Time",
            "abbr": "AST",
            "offset": 4,
            "isdst": false,
            "text": "(UTC+04:00) Abu Dhabi, Muscat",
            "utc": [
                  "Asia/Dubai",
                  "Asia/Muscat",
                  "Etc/GMT-4"
            ]
      },
      {
            "value": "Azerbaijan Standard Time",
            "abbr": "ADT",
            "offset": 5,
            "isdst": true,
            "text": "(UTC+04:00) Baku",
            "utc": [
                  "Asia/Baku"
            ]
      },
      {
            "value": "Mauritius Standard Time",
            "abbr": "MST",
            "offset": 4,
            "isdst": false,
            "text": "(UTC+04:00) Port Louis",
            "utc": [
                  "Indian/Mahe",
                  "Indian/Mauritius",
                  "Indian/Reunion"
            ]
      },
      {
            "value": "Georgian Standard Time",
            "abbr": "GET",
            "offset": 4,
            "isdst": false,
            "text": "(UTC+04:00) Tbilisi",
            "utc": [
                  "Asia/Tbilisi"
            ]
      },
      {
            "value": "Caucasus Standard Time",
            "abbr": "CST",
            "offset": 4,
            "isdst": false,
            "text": "(UTC+04:00) Yerevan",
            "utc": [
                  "Asia/Yerevan"
            ]
      },
      {
            "value": "Afghanistan Standard Time",
            "abbr": "AST",
            "offset": 4.5,
            "isdst": false,
            "text": "(UTC+04:30) Kabul",
            "utc": [
                  "Asia/Kabul"
            ]
      },
      {
            "value": "West Asia Standard Time",
            "abbr": "WAST",
            "offset": 5,
            "isdst": false,
            "text": "(UTC+05:00) Ashgabat, Tashkent",
            "utc": [
                  "Antarctica/Mawson",
                  "Asia/Aqtau",
                  "Asia/Aqtobe",
                  "Asia/Ashgabat",
                  "Asia/Dushanbe",
                  "Asia/Oral",
                  "Asia/Samarkand",
                  "Asia/Tashkent",
                  "Etc/GMT-5",
                  "Indian/Kerguelen",
                  "Indian/Maldives"
            ]
      },
      {
            "value": "Yekaterinburg Time",
            "abbr": "YEKT",
            "offset": 5,
            "isdst": false,
            "text": "(UTC+05:00) Yekaterinburg",
            "utc": [
                  "Asia/Yekaterinburg"
            ]
      },
      {
            "value": "Pakistan Standard Time",
            "abbr": "PKT",
            "offset": 5,
            "isdst": false,
            "text": "(UTC+05:00) Islamabad, Karachi",
            "utc": [
                  "Asia/Karachi"
            ]
      },
      {
            "value": "India Standard Time",
            "abbr": "IST",
            "offset": 5.5,
            "isdst": false,
            "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "utc": [
                  "Asia/Kolkata"
            ]
      },
      {
            "value": "Sri Lanka Standard Time",
            "abbr": "SLST",
            "offset": 5.5,
            "isdst": false,
            "text": "(UTC+05:30) Sri Jayawardenepura",
            "utc": [
                  "Asia/Colombo"
            ]
      },
      {
            "value": "Nepal Standard Time",
            "abbr": "NST",
            "offset": 5.75,
            "isdst": false,
            "text": "(UTC+05:45) Kathmandu",
            "utc": [
                  "Asia/Kathmandu"
            ]
      },
      {
            "value": "Central Asia Standard Time",
            "abbr": "CAST",
            "offset": 6,
            "isdst": false,
            "text": "(UTC+06:00) Nur-Sultan (Astana)",
            "utc": [
                  "Antarctica/Vostok",
                  "Asia/Almaty",
                  "Asia/Bishkek",
                  "Asia/Qyzylorda",
                  "Asia/Urumqi",
                  "Etc/GMT-6",
                  "Indian/Chagos"
            ]
      },
      {
            "value": "Bangladesh Standard Time",
            "abbr": "BST",
            "offset": 6,
            "isdst": false,
            "text": "(UTC+06:00) Dhaka",
            "utc": [
                  "Asia/Dhaka",
                  "Asia/Thimphu"
            ]
      },
      {
            "value": "Myanmar Standard Time",
            "abbr": "MST",
            "offset": 6.5,
            "isdst": false,
            "text": "(UTC+06:30) Yangon (Rangoon)",
            "utc": [
                  "Asia/Rangoon",
                  "Indian/Cocos"
            ]
      },
      {
            "value": "SE Asia Standard Time",
            "abbr": "SAST",
            "offset": 7,
            "isdst": false,
            "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
            "utc": [
                  "Antarctica/Davis",
                  "Asia/Bangkok",
                  "Asia/Hovd",
                  "Asia/Jakarta",
                  "Asia/Phnom_Penh",
                  "Asia/Pontianak",
                  "Asia/Saigon",
                  "Asia/Vientiane",
                  "Etc/GMT-7",
                  "Indian/Christmas"
            ]
      },
      {
            "value": "N. Central Asia Standard Time",
            "abbr": "NCAST",
            "offset": 7,
            "isdst": false,
            "text": "(UTC+07:00) Novosibirsk",
            "utc": [
                  "Asia/Novokuznetsk",
                  "Asia/Novosibirsk",
                  "Asia/Omsk"
            ]
      },
      {
            "value": "China Standard Time",
            "abbr": "CST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
            "utc": [
                  "Asia/Hong_Kong",
                  "Asia/Macau",
                  "Asia/Shanghai"
            ]
      },
      {
            "value": "North Asia Standard Time",
            "abbr": "NAST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Krasnoyarsk",
            "utc": [
                  "Asia/Krasnoyarsk"
            ]
      },
      {
            "value": "Singapore Standard Time",
            "abbr": "MPST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Kuala Lumpur, Singapore",
            "utc": [
                  "Asia/Brunei",
                  "Asia/Kuala_Lumpur",
                  "Asia/Kuching",
                  "Asia/Makassar",
                  "Asia/Manila",
                  "Asia/Singapore",
                  "Etc/GMT-8"
            ]
      },
      {
            "value": "W. Australia Standard Time",
            "abbr": "WAST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Perth",
            "utc": [
                  "Antarctica/Casey",
                  "Australia/Perth"
            ]
      },
      {
            "value": "Taipei Standard Time",
            "abbr": "TST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Taipei",
            "utc": [
                  "Asia/Taipei"
            ]
      },
      {
            "value": "Ulaanbaatar Standard Time",
            "abbr": "UST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Ulaanbaatar",
            "utc": [
                  "Asia/Choibalsan",
                  "Asia/Ulaanbaatar"
            ]
      },
      {
            "value": "North Asia East Standard Time",
            "abbr": "NAEST",
            "offset": 8,
            "isdst": false,
            "text": "(UTC+08:00) Irkutsk",
            "utc": [
                  "Asia/Irkutsk"
            ]
      },
      {
            "value": "Japan Standard Time",
            "abbr": "JST",
            "offset": 9,
            "isdst": false,
            "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
            "utc": [
                  "Asia/Dili",
                  "Asia/Jayapura",
                  "Asia/Tokyo",
                  "Etc/GMT-9",
                  "Pacific/Palau"
            ]
      },
      {
            "value": "Korea Standard Time",
            "abbr": "KST",
            "offset": 9,
            "isdst": false,
            "text": "(UTC+09:00) Seoul",
            "utc": [
                  "Asia/Pyongyang",
                  "Asia/Seoul"
            ]
      },
      {
            "value": "Cen. Australia Standard Time",
            "abbr": "CAST",
            "offset": 9.5,
            "isdst": false,
            "text": "(UTC+09:30) Adelaide",
            "utc": [
                  "Australia/Adelaide",
                  "Australia/Broken_Hill"
            ]
      },
      {
            "value": "AUS Central Standard Time",
            "abbr": "ACST",
            "offset": 9.5,
            "isdst": false,
            "text": "(UTC+09:30) Darwin",
            "utc": [
                  "Australia/Darwin"
            ]
      },
      {
            "value": "E. Australia Standard Time",
            "abbr": "EAST",
            "offset": 10,
            "isdst": false,
            "text": "(UTC+10:00) Brisbane",
            "utc": [
                  "Australia/Brisbane",
                  "Australia/Lindeman"
            ]
      },
      {
            "value": "AUS Eastern Standard Time",
            "abbr": "AEST",
            "offset": 10,
            "isdst": false,
            "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
            "utc": [
                  "Australia/Melbourne",
                  "Australia/Sydney"
            ]
      },
      {
            "value": "West Pacific Standard Time",
            "abbr": "WPST",
            "offset": 10,
            "isdst": false,
            "text": "(UTC+10:00) Guam, Port Moresby",
            "utc": [
                  "Antarctica/DumontDUrville",
                  "Etc/GMT-10",
                  "Pacific/Guam",
                  "Pacific/Port_Moresby",
                  "Pacific/Saipan",
                  "Pacific/Truk"
            ]
      },
      {
            "value": "Tasmania Standard Time",
            "abbr": "TST",
            "offset": 10,
            "isdst": false,
            "text": "(UTC+10:00) Hobart",
            "utc": [
                  "Australia/Currie",
                  "Australia/Hobart"
            ]
      },
      {
            "value": "Yakutsk Standard Time",
            "abbr": "YST",
            "offset": 9,
            "isdst": false,
            "text": "(UTC+09:00) Yakutsk",
            "utc": [
                  "Asia/Chita",
                  "Asia/Khandyga",
                  "Asia/Yakutsk"
            ]
      },
      {
            "value": "Central Pacific Standard Time",
            "abbr": "CPST",
            "offset": 11,
            "isdst": false,
            "text": "(UTC+11:00) Solomon Is., New Caledonia",
            "utc": [
                  "Antarctica/Macquarie",
                  "Etc/GMT-11",
                  "Pacific/Efate",
                  "Pacific/Guadalcanal",
                  "Pacific/Kosrae",
                  "Pacific/Noumea",
                  "Pacific/Ponape"
            ]
      },
      {
            "value": "Vladivostok Standard Time",
            "abbr": "VST",
            "offset": 11,
            "isdst": false,
            "text": "(UTC+11:00) Vladivostok",
            "utc": [
                  "Asia/Sakhalin",
                  "Asia/Ust-Nera",
                  "Asia/Vladivostok"
            ]
      },
      {
            "value": "New Zealand Standard Time",
            "abbr": "NZST",
            "offset": 12,
            "isdst": false,
            "text": "(UTC+12:00) Auckland, Wellington",
            "utc": [
                  "Antarctica/McMurdo",
                  "Pacific/Auckland"
            ]
      },
      {
            "value": "UTC+12",
            "abbr": "U",
            "offset": 12,
            "isdst": false,
            "text": "(UTC+12:00) Coordinated Universal Time+12",
            "utc": [
                  "Etc/GMT-12",
                  "Pacific/Funafuti",
                  "Pacific/Kwajalein",
                  "Pacific/Majuro",
                  "Pacific/Nauru",
                  "Pacific/Tarawa",
                  "Pacific/Wake",
                  "Pacific/Wallis"
            ]
      },
      {
            "value": "Fiji Standard Time",
            "abbr": "FST",
            "offset": 12,
            "isdst": false,
            "text": "(UTC+12:00) Fiji",
            "utc": [
                  "Pacific/Fiji"
            ]
      },
      {
            "value": "Magadan Standard Time",
            "abbr": "MST",
            "offset": 12,
            "isdst": false,
            "text": "(UTC+12:00) Magadan",
            "utc": [
                  "Asia/Anadyr",
                  "Asia/Kamchatka",
                  "Asia/Magadan",
                  "Asia/Srednekolymsk"
            ]
      },
      {
            "value": "Kamchatka Standard Time",
            "abbr": "KDT",
            "offset": 13,
            "isdst": true,
            "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
            "utc": [
                  "Asia/Kamchatka"
            ]
      },
      {
            "value": "Tonga Standard Time",
            "abbr": "TST",
            "offset": 13,
            "isdst": false,
            "text": "(UTC+13:00) Nuku'alofa",
            "utc": [
                  "Etc/GMT-13",
                  "Pacific/Enderbury",
                  "Pacific/Fakaofo",
                  "Pacific/Tongatapu"
            ]
      },
      {
            "value": "Samoa Standard Time",
            "abbr": "SST",
            "offset": 13,
            "isdst": false,
            "text": "(UTC+13:00) Samoa",
            "utc": [
                  "Pacific/Apia"
            ]
      }
]
export const COUNTRY_JSON = [
      { "name": "United States", "code": "US" },
      { "name": "United Kingdom", "code": "GB" },
      { "name": "Australia", "code": "AU" },
      { "name": "Afghanistan", "code": "AF" },
      { "name": "Åland Islands", "code": "AX" },
      { "name": "Albania", "code": "AL" },
      { "name": "Algeria", "code": "DZ" },
      { "name": "American Samoa", "code": "AS" },
      { "name": "AndorrA", "code": "AD" },
      { "name": "Angola", "code": "AO" },
      { "name": "Anguilla", "code": "AI" },
      { "name": "Antarctica", "code": "AQ" },
      { "name": "Antigua and Barbuda", "code": "AG" },
      { "name": "Argentina", "code": "AR" },
      { "name": "Armenia", "code": "AM" },
      { "name": "Aruba", "code": "AW" },
      { "name": "Austria", "code": "AT" },
      { "name": "Azerbaijan", "code": "AZ" },
      { "name": "Bahamas", "code": "BS" },
      { "name": "Bahrain", "code": "BH" },
      { "name": "Bangladesh", "code": "BD" },
      { "name": "Barbados", "code": "BB" },
      { "name": "Belarus", "code": "BY" },
      { "name": "Belgium", "code": "BE" },
      { "name": "Belize", "code": "BZ" },
      { "name": "Benin", "code": "BJ" },
      { "name": "Bermuda", "code": "BM" },
      { "name": "Bhutan", "code": "BT" },
      { "name": "Bolivia", "code": "BO" },
      { "name": "Bosnia and Herzegovina", "code": "BA" },
      { "name": "Botswana", "code": "BW" },
      { "name": "Bouvet Island", "code": "BV" },
      { "name": "Brazil", "code": "BR" },
      { "name": "British Indian Ocean Territory", "code": "IO" },
      { "name": "Brunei Darussalam", "code": "BN" },
      { "name": "Bulgaria", "code": "BG" },
      { "name": "Burkina Faso", "code": "BF" },
      { "name": "Burundi", "code": "BI" },
      { "name": "Cambodia", "code": "KH" },
      { "name": "Cameroon", "code": "CM" },
      { "name": "Canada", "code": "CA" },
      { "name": "Cape Verde", "code": "CV" },
      { "name": "Cayman Islands", "code": "KY" },
      { "name": "Central African Republic", "code": "CF" },
      { "name": "Chad", "code": "TD" },
      { "name": "Chile", "code": "CL" },
      { "name": "China", "code": "CN" },
      { "name": "Christmas Island", "code": "CX" },
      { "name": "Cocos (Keeling) Islands", "code": "CC" },
      { "name": "Colombia", "code": "CO" },
      { "name": "Comoros", "code": "KM" },
      { "name": "Congo", "code": "CG" },
      { "name": "Congo, The Democratic Republic of the", "code": "CD" },
      { "name": "Cook Islands", "code": "CK" },
      { "name": "Costa Rica", "code": "CR" },
      { "name": "Cote D'Ivoire", "code": "CI" },
      { "name": "Croatia", "code": "HR" },
      { "name": "Cuba", "code": "CU" },
      { "name": "Cyprus", "code": "CY" },
      { "name": "Czech Republic", "code": "CZ" },
      { "name": "Denmark", "code": "DK" },
      { "name": "Djibouti", "code": "DJ" },
      { "name": "Dominica", "code": "DM" },
      { "name": "Dominican Republic", "code": "DO" },
      { "name": "Ecuador", "code": "EC" },
      { "name": "Egypt", "code": "EG" },
      { "name": "El Salvador", "code": "SV" },
      { "name": "Equatorial Guinea", "code": "GQ" },
      { "name": "Eritrea", "code": "ER" },
      { "name": "Estonia", "code": "EE" },
      { "name": "Ethiopia", "code": "ET" },
      { "name": "Falkland Islands (Malvinas)", "code": "FK" },
      { "name": "Faroe Islands", "code": "FO" },
      { "name": "Fiji", "code": "FJ" },
      { "name": "Finland", "code": "FI" },
      { "name": "France", "code": "FR" },
      { "name": "French Guiana", "code": "GF" },
      { "name": "French Polynesia", "code": "PF" },
      { "name": "French Southern Territories", "code": "TF" },
      { "name": "Gabon", "code": "GA" },
      { "name": "Gambia", "code": "GM" },
      { "name": "Georgia", "code": "GE" },
      { "name": "Germany", "code": "DE" },
      { "name": "Ghana", "code": "GH" },
      { "name": "Gibraltar", "code": "GI" },
      { "name": "Greece", "code": "GR" },
      { "name": "Greenland", "code": "GL" },
      { "name": "Grenada", "code": "GD" },
      { "name": "Guadeloupe", "code": "GP" },
      { "name": "Guam", "code": "GU" },
      { "name": "Guatemala", "code": "GT" },
      { "name": "Guernsey", "code": "GG" },
      { "name": "Guinea", "code": "GN" },
      { "name": "Guinea-Bissau", "code": "GW" },
      { "name": "Guyana", "code": "GY" },
      { "name": "Haiti", "code": "HT" },
      { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
      { "name": "Holy See (Vatican City State)", "code": "VA" },
      { "name": "Honduras", "code": "HN" },
      { "name": "Hong Kong", "code": "HK" },
      { "name": "Hungary", "code": "HU" },
      { "name": "Iceland", "code": "IS" },
      { "name": "India", "code": "IN" },
      { "name": "Indonesia", "code": "ID" },
      { "name": "Iran, Islamic Republic Of", "code": "IR" },
      { "name": "Iraq", "code": "IQ" },
      { "name": "Ireland", "code": "IE" },
      { "name": "Isle of Man", "code": "IM" },
      { "name": "Israel", "code": "IL" },
      { "name": "Italy", "code": "IT" },
      { "name": "Jamaica", "code": "JM" },
      { "name": "Japan", "code": "JP" },
      { "name": "Jersey", "code": "JE" },
      { "name": "Jordan", "code": "JO" },
      { "name": "Kazakhstan", "code": "KZ" },
      { "name": "Kenya", "code": "KE" },
      { "name": "Kiribati", "code": "KI" },
      { "name": "Korea, Democratic People'S Republic of", "code": "KP" },
      { "name": "Korea, Republic of", "code": "KR" },
      { "name": "Kuwait", "code": "KW" },
      { "name": "Kyrgyzstan", "code": "KG" },
      { "name": "Lao People'S Democratic Republic", "code": "LA" },
      { "name": "Latvia", "code": "LV" },
      { "name": "Lebanon", "code": "LB" },
      { "name": "Lesotho", "code": "LS" },
      { "name": "Liberia", "code": "LR" },
      { "name": "Libyan Arab Jamahiriya", "code": "LY" },
      { "name": "Liechtenstein", "code": "LI" },
      { "name": "Lithuania", "code": "LT" },
      { "name": "Luxembourg", "code": "LU" },
      { "name": "Macao", "code": "MO" },
      { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
      { "name": "Madagascar", "code": "MG" },
      { "name": "Malawi", "code": "MW" },
      { "name": "Malaysia", "code": "MY" },
      { "name": "Maldives", "code": "MV" },
      { "name": "Mali", "code": "ML" },
      { "name": "Malta", "code": "MT" },
      { "name": "Marshall Islands", "code": "MH" },
      { "name": "Martinique", "code": "MQ" },
      { "name": "Mauritania", "code": "MR" },
      { "name": "Mauritius", "code": "MU" },
      { "name": "Mayotte", "code": "YT" },
      { "name": "Mexico", "code": "MX" },
      { "name": "Micronesia, Federated States of", "code": "FM" },
      { "name": "Moldova, Republic of", "code": "MD" },
      { "name": "Monaco", "code": "MC" },
      { "name": "Mongolia", "code": "MN" },
      { "name": "Montserrat", "code": "MS" },
      { "name": "Morocco", "code": "MA" },
      { "name": "Mozambique", "code": "MZ" },
      { "name": "Myanmar", "code": "MM" },
      { "name": "Namibia", "code": "NA" },
      { "name": "Nauru", "code": "NR" },
      { "name": "Nepal", "code": "NP" },
      { "name": "Netherlands", "code": "NL" },
      { "name": "Netherlands Antilles", "code": "AN" },
      { "name": "New Caledonia", "code": "NC" },
      { "name": "New Zealand", "code": "NZ" },
      { "name": "Nicaragua", "code": "NI" },
      { "name": "Niger", "code": "NE" },
      { "name": "Nigeria", "code": "NG" },
      { "name": "Niue", "code": "NU" },
      { "name": "Norfolk Island", "code": "NF" },
      { "name": "Northern Mariana Islands", "code": "MP" },
      { "name": "Norway", "code": "NO" },
      { "name": "Oman", "code": "OM" },
      { "name": "Pakistan", "code": "PK" },
      { "name": "Palau", "code": "PW" },
      { "name": "Palestinian Territory, Occupied", "code": "PS" },
      { "name": "Panama", "code": "PA" },
      { "name": "Papua New Guinea", "code": "PG" },
      { "name": "Paraguay", "code": "PY" },
      { "name": "Peru", "code": "PE" },
      { "name": "Philippines", "code": "PH" },
      { "name": "Pitcairn", "code": "PN" },
      { "name": "Poland", "code": "PL" },
      { "name": "Portugal", "code": "PT" },
      { "name": "Puerto Rico", "code": "PR" },
      { "name": "Qatar", "code": "QA" },
      { "name": "Reunion", "code": "RE" },
      { "name": "Romania", "code": "RO" },
      { "name": "Russian Federation", "code": "RU" },
      { "name": "RWANDA", "code": "RW" },
      { "name": "Saint Helena", "code": "SH" },
      { "name": "Saint Kitts and Nevis", "code": "KN" },
      { "name": "Saint Lucia", "code": "LC" },
      { "name": "Saint Pierre and Miquelon", "code": "PM" },
      { "name": "Saint Vincent and the Grenadines", "code": "VC" },
      { "name": "Samoa", "code": "WS" },
      { "name": "San Marino", "code": "SM" },
      { "name": "Sao Tome and Principe", "code": "ST" },
      { "name": "Saudi Arabia", "code": "SA" },
      { "name": "Senegal", "code": "SN" },
      { "name": "Serbia and Montenegro", "code": "CS" },
      { "name": "Seychelles", "code": "SC" },
      { "name": "Sierra Leone", "code": "SL" },
      { "name": "Singapore", "code": "SG" },
      { "name": "Slovakia", "code": "SK" },
      { "name": "Slovenia", "code": "SI" },
      { "name": "Solomon Islands", "code": "SB" },
      { "name": "Somalia", "code": "SO" },
      { "name": "South Africa", "code": "ZA" },
      { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
      { "name": "Spain", "code": "ES" },
      { "name": "Sri Lanka", "code": "LK" },
      { "name": "Sudan", "code": "SD" },
      { "name": "Suriname", "code": "SR" },
      { "name": "Svalbard and Jan Mayen", "code": "SJ" },
      { "name": "Swaziland", "code": "SZ" },
      { "name": "Sweden", "code": "SE" },
      { "name": "Switzerland", "code": "CH" },
      { "name": "Syrian Arab Republic", "code": "SY" },
      { "name": "Taiwan, Province of China", "code": "TW" },
      { "name": "Tajikistan", "code": "TJ" },
      { "name": "Tanzania, United Republic of", "code": "TZ" },
      { "name": "Thailand", "code": "TH" },
      { "name": "Timor-Leste", "code": "TL" },
      { "name": "Togo", "code": "TG" },
      { "name": "Tokelau", "code": "TK" },
      { "name": "Tonga", "code": "TO" },
      { "name": "Trinidad and Tobago", "code": "TT" },
      { "name": "Tunisia", "code": "TN" },
      { "name": "Turkey", "code": "TR" },
      { "name": "Turkmenistan", "code": "TM" },
      { "name": "Turks and Caicos Islands", "code": "TC" },
      { "name": "Tuvalu", "code": "TV" },
      { "name": "Uganda", "code": "UG" },
      { "name": "Ukraine", "code": "UA" },
      { "name": "United Arab Emirates", "code": "AE" },
      { "name": "United States Minor Outlying Islands", "code": "UM" },
      { "name": "Uruguay", "code": "UY" },
      { "name": "Uzbekistan", "code": "UZ" },
      { "name": "Vanuatu", "code": "VU" },
      { "name": "Venezuela", "code": "VE" },
      { "name": "Viet Nam", "code": "VN" },
      { "name": "Virgin Islands, British", "code": "VG" },
      { "name": "Virgin Islands, U.S.", "code": "VI" },
      { "name": "Wallis and Futuna", "code": "WF" },
      { "name": "Western Sahara", "code": "EH" },
      { "name": "Yemen", "code": "YE" },
      { "name": "Zambia", "code": "ZM" },
      { "name": "Zimbabwe", "code": "ZW" }
]

export const ENGLISH = {
      "flag": "https://flagcdn.com/w160/us.png",
      "supported": true,
      "name": "English",
      "code": "en-us",
      "id": "63d7b068c932f816665e8195"
    }
