import * as types from './types';
import { Reducer } from 'redux';
import { ActionType } from '../interface';
import { UploadFile } from 'antd/lib/upload/interface';

interface initalStateProps {
    LinePosition: any;
    CaptionPosition: any;
    FontFamily: any;
    // FontWeight: any;
    ToggleUnderline: any;
    ToggleItalic: any;
    Uppercase: any;
    Count: any;
    AiKeywordHighlighter: any;
    AiKeywordStrokeHighlighter: any;
    ChangeLayoutSetting: any;
    // FontShadowColor: any;
    // FontShadowX: any;
    // FontShadowY: any;
    // FontShadowBlur: any;
    FontStrokeColor: any;
    ThicknessSliderValue: any;
    FontColor: any;
    FileImage: any;
    tabTitle: string;
    
}

const initState: initalStateProps = {
     LinePosition: 1,
     CaptionPosition: 'auto',
     FontFamily: 'KomikaAxis-Bold',
    //  FontWeight: 'Bold',
     ToggleUnderline: false,
     ToggleItalic: false,
     Uppercase: false,
     Count: 42,
     AiKeywordHighlighter: '#fff705',
     AiKeywordStrokeHighlighter: '#373434',
     ChangeLayoutSetting: 'fill',
    //  FontShadowColor: '#707070',
    //  FontShadowX: 0,
    //  FontShadowY: 0,
    //  FontShadowBlur: 0,
     FontStrokeColor: '#000000',
     ThicknessSliderValue: 0,
     FontColor: '#fcfcfc',
     FileImage: "",
     tabTitle: "Home",
    };

const brandingReducer: Reducer<initalStateProps, ActionType> = (state = initState, action) => {
    switch (action.type) {
        case types.LINE_POSITION:
            return {
                ...state,
                LinePosition: action.payload?.payload
            };
        case types.CAPTION_POSITION:
            return {
                ...state,
                CaptionPosition: action.payload?.payload
            };
        case types.FONT_FAMILY:
            return {
                ...state,
                FontFamily: action.payload?.payload
            };
        // case types.FONT_WEIGHT:
        //     return {
        //         ...state,
        //         FontWeight: action.payload?.payload
        //     };
        case types.TOGGLE_UNDERLINE:
            return {
                ...state,
                ToggleUnderline: action.payload?.payload
            };
        case types.TOGGLE_ITALIC:
            return {
                ...state,
                ToggleItalic: action.payload?.payload
            };
        case types.UPPERCASE:
            return {
                ...state,
                Uppercase: action.payload?.payload
            };
        case types.COUNT:
            return {
                ...state,
                Count: action.payload?.payload
            };
        case types.AI_KEYWORD_HEIGHLIGHTER:
            return {
                ...state,
                AiKeywordHighlighter: action.payload?.payload
            };
        case types.AI_KEYWORD_STROKE_HEIGHLIGHTER:
            return {
                ...state,
                AiKeywordStrokeHighlighter: action.payload?.payload
            };
        case types.CHANGE_LAYOUT_SETTING:
            return {
                ...state,
                ChangeLayoutSetting: action.payload?.payload
            };
        // case types.FONT_SHADOW_COLOR:
        //     return {
        //         ...state,
        //         FontShadowColor: action.payload?.payload
        //     };
        // case types.FONT_SHADOW_X:
        //     return {
        //         ...state,
        //         FontShadowX: action.payload?.payload
        //     };
        // case types.FONT_SHADOW_Y:
        //     return {
        //         ...state,
        //         FontShadowY: action.payload?.payload
        //     };
        // case types.FONT_SHADOW_BLUR:
        //     return {
        //         ...state,
        //         FontShadowBlur: action.payload?.payload
        //     };
        case types.FONT_STROKE_COLOR:
            return {
                ...state,
                FontStrokeColor: action.payload?.payload
            };
        case types.THICKNESS_SLIDER_VALUE:
            return {
                ...state,
                ThicknessSliderValue: action.payload?.payload
            };
        case types.FONT_COLOR:
            return {
                ...state,
                FontColor: action.payload?.payload
            };
        case types.FILE_IMAGE:
            return {
                ...state,
                FileImage: action.payload
            };
        case types.SET_TAB_TITLE:
            return {
                ...state,
                tabTitle: action.payload
            };
        case types.RESET_DATA:
            return initState;
        case types.SAVE_ALL_BRANDING_DATA_SUCCESS:
            const  data  = action.payload;
            console.log(data,"data====== reducer")
            return {
                ...state,
                LinePosition: data.subtitleStyle.linesPerFrame,
                // FontWeight: data.subtitleStyle.fontWeight,
                FontFamily: data.subtitleStyle.fontFamily,
                Count: data.subtitleStyle.fontSize,
                FontColor: data.subtitleStyle.primaryColor,
                FontStrokeColor: data.subtitleStyle.primaryStrokeColor,
                AiKeywordHighlighter: data.subtitleStyle.secondaryColor,
                AiKeywordStrokeHighlighter: data.subtitleStyle.secondaryStrokeColor,
                // FontShadowColor: data.subtitleStyle.textShadowColor,
                // FontShadowX: data.subtitleStyle.xpos,
                // FontShadowY: data.subtitleStyle.ypos,
                // FontShadowBlur: data.subtitleStyle.backgroundColor,//not assign
                CaptionPosition: data.subtitleStyle.vAlign,
                ChangeLayoutSetting: data.layout,
                BoxWidth: data.subtitleStyle.boxWidth,//not assign
                BoxHeight: data.subtitleStyle.boxHeight,//not assign
                LineHeight: data.subtitleStyle.lineHeight,//not assign
                ThicknessSliderValue: data.subtitleStyle.data,//not assign
                ToggleItalic: data.subtitleStyle.toggleItalic,//not assign
                ToggleUnderline: data.subtitleStyle.toggleUnderline,//not assign
                uppercase: data.subtitleStyle.uppercase,
                FileImage:{
                    id: data.elements[0].id,
                    type: data.elements[0].type,
                    active: data.elements[0].active,
                    rotation: data.elements[0].rotation,
                    src:  data.elements[0].src ,
                    name:  data.elements[0].name,
                    width: data.elements[0].width,
                    height: data.elements[0].height,
                    xpos: data.elements[0].xpos,
                    ypos: data.elements[0].ypos,
                }
            };
        default:
            return state; 
    }
};

export default brandingReducer;
