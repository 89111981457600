import { Reducer } from 'redux';
import { FileArray } from 'chonky';

import * as types from './types';

import { workspaceType } from './DataProps';
import { updateDirHelper, updateFolderChainHelper, addProjectToArray } from '../../utils/utils';
import { CTA } from '../../utils/database';
import { PAGINATION_LIMIT, PROJECT } from '../../utils/constants';
import { ActionType } from '../interface';


interface initalStateProps {
  projectsAndDirectories: [] | any;
  directories: [] | any;
  allDirectories: [] | any;
  projects: [] | any;
  currentWorkspace: workspaceType | null;
  onBoardingSettings: any | null;
  currentParentDirectory: string | null;
  currentProject: any | null;
  templates: any | [],
  categoriesList: any | [],
  folderChain: FileArray;
  isIframeVisible: boolean;
  fbLoading: boolean;
  nextPage: number;
  nextPageTemplate: number;
  statistics: any | [];
  downloadYTSuccess: any | null;
  allSupportedLanguages: [] | any;
  allWorkSpaces: [] | any;
}

const initState: initalStateProps = {
  currentWorkspace: null,
  directories: null,
  allDirectories: null,
  projects: null,
  onBoardingSettings: {
    currency: "USD",
    timeZone: "UTC"
  },
  projectsAndDirectories: null,
  currentParentDirectory: null,
  currentProject: null,
  templates: null,
  categoriesList: [{ name: "SEO" }],
  folderChain: [],
  isIframeVisible: false,
  fbLoading: false,
  nextPage: 1,
  nextPageTemplate: 1,
  statistics: null,
  downloadYTSuccess: null,
  allSupportedLanguages: [],
  allWorkSpaces: [],
}

const fileBrowserReducer: Reducer<initalStateProps, ActionType> = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_NEW_DIR_SUCCESS:
      return {
        ...state,
        directories: [action.payload, ...state.directories],
        //TODO in Move Functionality
        // allDirectories: [action.payload, ...state.allDirectories]
      }
    case types.UPDATE_WORKSPACE:
      return {
        ...state,
        currentWorkspace: action.payload
      }
    case types.UPDATE_WORKSPACES:
      return {
        ...state,
        allWorkSpaces: action.payload
        }
    case types.SET_CURRENT_PROJECT:
        return {
          ...state,
          currentProject: action.payload
        }
    case types.UPDATE_DIR_SUCCESS:
      return {
        ...state,
        directories: updateDirHelper(action.payload, state.directories, state.currentParentDirectory)
      }
    case types.ADD_PROJECT_LOCAL:

      return {
        ...state,
        projects: addProjectToArray(state.projects, action.payload, PROJECT)
      }
    case types.DELETE_DIR_SUCCESS:
      return {
        ...state,
        directories: state.directories?.filter((f: any) => f?._id !== action.payload._id),
        allDirectories: state.allDirectories?.filter((f: any) => f?._id !== action.payload._id)
      }
    case types.UPDATE_PROJECT_SUCCESS:
      console.log(action.payload);

      return {
        ...state,
        projects: updateDirHelper(action.payload, state.projects, state.currentParentDirectory)
      }
    case types.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects?.filter((f: any) => f?._id !== action.payload._id)
      }
      case types.RESET_FOLDER_CHAIN:
        return {
          ...state,
          folderChain: [],
        }
    case types.UPDATE_FOLDER_CHAIN:
      return {
        ...state,
        directories: null,
        projects: null,
        folderChain: updateFolderChainHelper(state.folderChain, action.payload),
        currentParentDirectory: state.currentWorkspace?._id === action.payload?._id ? null : action.payload?._id
      }
    case types.UPDATE_CURRENT_DIRECTORY:
      return state.currentWorkspace?._id === action.payload._id ?
        {
          ...state,
          nextPage: 1,
          directories: null,
          projects: null,
          currentParentDirectory: null
        }
        : {
          ...state,
          nextPage: 1,
          directories: null,
          projects: null,
        }

    case types.SHOW_IFRAME:
      return {
        ...state,
        isIframeVisible: action.payload.state
      }

    case types.FB_LOADING:
      return {
        ...state,
        fbLoading: action.payload
      }
    case types.ADD_PROJECT_KEEVI_SUCCESS:
      const pros = state.projects ? state.projects : [];
      return {
        ...state,
        projects: [action.payload, ...pros]
      }
    case types.ADD_PROJECT_VUMU_SUCCESS:

      return action.payload.videoSettings && action.payload.videoSettings.isTemplate ? state : {
        ...state,
        projects: addProjectToArray(state.projects, action.payload, PROJECT)
      }

    case types.UPDATE_PROJECT_VUMU_SUCCESS:
      return {
        ...state,
        projects: updateDirHelper(action.payload, state.projects, state.currentParentDirectory)
      }

    case types.FETCH_TEMPLATES_SUCCESS:
      console.log({ templates: action.payload });
      return { ...state, templates: action.payload };

    case types.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoriesList: action.payload };

    case types.SET_PROJECTS_TO_EMPTY:
      return {
        ...state,
        directories: null,
        projects: null,
      }

    case types.GET_STATISTICS_REQUEST_SUCCESS:
      return {
        ...state,
        statistics: action.payload
      }
    case types.ADD_ONBOARDING_SETTINGS:
      return {
        ...state,
        onBoardingSettings: action.payload
      }

    case types.SET_NEXT_PAGE:
      return {
        ...state,
        nextPage: action.payload
      }
    case types.FETCH_ALL_DIR_SUCCESS:
      const tempDir = {
        ...state,
        directories: action.payload,
      }
      return !state.currentParentDirectory ? {
        ...tempDir,
        folderChain: updateFolderChainHelper(state.folderChain, state.currentWorkspace),
      } : tempDir

    case types.FETCH_ALL_PRO_SUCCESS:
      const tempArray = state.projects ? state.projects : [];
      const tempPro = {
        ...state,
        projects: [...tempArray, ...action.payload],
        nextPage: action.payload.length < PAGINATION_LIMIT ? 0 : state.nextPage + 1
      }
      return !state.currentParentDirectory ? {
        ...tempPro,
        folderChain: updateFolderChainHelper(state.folderChain, state.currentWorkspace),
      } : tempPro
    case types.FETCH_ALL_DIR_WRK_SUCCESS:
      return {
        ...state,
        allDirectories: action.payload
      }
    case types.DOWNLOAD_YOUTUBE_VIDEO_PROGRESS_SUCCESS:
      return {
        ...state,
        downloadYTSuccess: action.payload
      }
    case types.SET_SUPPORTED_LANGUAGES:
      return {
        ...state,
        allSupportedLanguages: action.payload.languages
      }
    default:
      return state;
  }
}

export default fileBrowserReducer;
