import { createBrowserHistory } from 'history';
import { AnyAction, applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { reducers } from './reducers';
import rootSaga from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware<ThunkDispatch<RootState, undefined, AnyAction>>(...middlewares))
);
sagaMiddleware.run(rootSaga);
export { store, history };
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;