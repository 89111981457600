import { all } from 'redux-saga/effects';
import authSaga from '../auth/saga';
import fileBrowserSaga from '../file-browser/saga';
import appSaga from '../app/saga';
import brandingSaga from '../branding/saga';
import awsSaga from '..//aws/sags';

export default function* Sagas() {
  yield all([
    authSaga(),
    fileBrowserSaga(),
    brandingSaga(),
    appSaga(),
    awsSaga(),
  ]);
}