import { Reducer } from 'redux';
import { TRIMMING_PROGRESS } from '../action_types';
import { ActionType } from '../interface';

interface initalStateProps {
}

const initState: initalStateProps = {}

const trimmingReducer: Reducer<any, ActionType> = (state = initState, action: ActionType) => {
  switch (action.type) {
    case TRIMMING_PROGRESS: {
        state[action.payload.projectId] = { ...action.payload }

        return { ...state };
    }

    default:
      return state;
  }
}

export default trimmingReducer