import * as types from "./types";
import {message} from "antd";
import {callApiGateway} from "../../helper/api.helper";

export const addProjectKeeviSaga = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch({ type: types.FB_LOADING, payload: true });
        dispatch({ type: types.FB_LOADING, payload: true });

        try {
            const response = await addProjectReq(payload.payload)
            // console.log({ response });
            dispatch({ type: types.ADD_PROJECT_KEEVI_SUCCESS, payload: response })
            dispatch({ type: types.FB_LOADING, payload: false })

            resolve(response);
        } catch(error) {
            console.log({ error });
            message.destroy();
            message.error("Error creating project");
            resolve(error);
        }
    })
}

async function addProjectReq(payload: any) {
    return await callApiGateway({ url: '/add-project', payload, method: 'POST' })
}
