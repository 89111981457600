import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Redirect, Link, useLocation, useHistory } from 'react-router-dom';
import { HomeOutlined,BgColorsOutlined } from '@ant-design/icons';
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard_icon.svg';
import { ReactComponent as LogoIcon } from '../assets/icons/logo.svg';
import Header from '../component/header';
import { RootState } from '../redux/store';
import api from '../config';
import AppLoader from '../component/Apploader';
import Modal from 'react-bootstrap/Modal';
import {
  GroupOutlined,
  CloseOutlined,
  StarFilled,
  LeftOutlined,
  RightOutlined,
  MenuOutlined,
  TeamOutlined,
  PlusOutlined,
  AppstoreOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import FileBrowserComponent from '../container/Dashboard/FileBrowser';
import { fetchWorkspace, setIframeVisibleAction, createWorkspaceTemplate, updateFolderChain, getAllDirAction, getAllProAction, updateCurrentDirectoryAction, resetFolderChain } from '../redux/file-browser/actions';
import config from '../config';
import { Button, Modal as BetaModal, Layout, Menu, Upload, message } from "antd";
import { upgradeModalVisibiltyAction } from '../redux/app/actions';
import OnBoardHeader from "../component/OnBoardHeader";
import OnBoardComponent from "../component/OnBoardComponent";
import * as types from '../redux/file-browser/types';
import { ResetAction } from '../redux/branding/actions';
import { PAGINATION_LIMIT } from '../utils/constants';

interface IProps {
  component: any;
  path: string;
  exact: boolean;
}

declare const window: any;
const { Sider, Content } = Layout;
const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.authReducer.isAuthenticated,
  );
  const socketConnected = useSelector(
    (state: RootState) => state.appReducer.socketConnected,
  );
  const isFree = useSelector((state: RootState) => state.appReducer.isFreeUser);
  const loaded = useSelector((state: RootState) => state.appReducer.loaded);
  const currentUser = useSelector((state: RootState) => state.authReducer.profile);
  const currentWorkspace = useSelector((state: RootState) => state.browserReducer.currentWorkspace);
  const allWorkSpaces = useSelector((state: RootState) => state.browserReducer.allWorkSpaces);
  const fbLoading = useSelector(
    (state: RootState) => state.browserReducer.fbLoading,
  );
  const location = useLocation(); 
  const upgradeModalVisible = useSelector((state: RootState) => state.appReducer.upgradeModalVisible);
  const currentParentDirectory = useSelector((state: RootState) => state.browserReducer.currentParentDirectory);
  const folderChain = useSelector(
    (state: RootState) => state.browserReducer.folderChain,
  );
  const [collapsed, setCollapsed] = useState(true);
  const [ifrmRef, setIfrmRef] = useState(null);
  const [crossButtonState, setCrossButtonState] = useState<boolean>(true);
  const [iFrameWidth, setIFrameWidth] = useState('100%');
  const [iFrameHeight, setIFrameHeight] = useState('80vh');
  const [extensionStatus, setExtensionStatus] = useState(null);
  const [isChromeBrowser, setIsChromeBrowser] = useState(null);
  const [isHideBanner, setIsHideBanner] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const history = useHistory()
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const { SubMenu } = Menu;
  const { Dragger } = Upload;
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    setIsHideBanner(!!window.sessionStorage.getItem('isHideBanner'));
  }, []);

  const isIframeVisible = useSelector(
    (state: RootState) => state.browserReducer.isIframeVisible,
  );

  useEffect(() => {
    extensionChecker();
  }, []);

  const extensionChecker = () => {
    const isChrome = window.chrome;
    setIsChromeBrowser(!!isChrome);
    if (isChrome) {
      const extensionInstalled = document.getElementById(
        '__vp__EXT_INSTALLED__',
      );
      //setExtensionStatus(!!extensionInstalled);
      setExtensionStatus(true);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener('message', e => {
      const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
      if (data && data.type === 'layout-resize') {
        setIFrameWidth(data.payload.width);
        setIFrameHeight(data.payload.height);
      }
      if (data && data.source === 'vumu-embed') {
        setCrossButtonState(data.payload.state);
      }
    });
  }, []);

  useEffect(() => {
    setCrossButtonState(true);
  }, [isIframeVisible]);

  useEffect(() => {
    if (api.iFrameUrl) {
      var ifrm = document.createElement('iframe');
      ifrm.setAttribute('id', 'iframe-vumu');
      ifrm.setAttribute('src', api.iFrameUrl);
      ifrm.setAttribute(
        'allow',
        `camera ${api.iFrameUrl}; microphone ${api.iFrameUrl}`,
      );
      ifrm.style.width = '100%';
      ifrm.style.border = 'none';
      ifrm.style.height = '100%';
      ifrm.style.display = 'none';
      setIfrmRef(ifrm);
      document.body.appendChild(ifrm);
      console.log(ifrmRef);
    }
  }, []);

  useEffect(() => {
    const iFrameParent = document.getElementById('iframeParent');
    if (isIframeVisible) {
      console.log(ifrmRef);
      iFrameParent?.appendChild(ifrmRef);
      ifrmRef.style.display = 'inline-block';
    } else {
      if (ifrmRef) {
        setIFrameWidth('100%');
        setIFrameHeight('80vh');
        iFrameParent?.removeChild(ifrmRef);
        ifrmRef?.contentWindow?.location.reload();
      }
    }
  }, [isIframeVisible]);
  useEffect(() => {
    if(currentWorkspace && !currentWorkspace.templatesGroupId) {
      dispatch(createWorkspaceTemplate({
          "userId": currentWorkspace.userId,
          "workspaceId": currentWorkspace._id,
          "ratio": "vertical",
          "type": "account",
          "subtitleStyle": {
              "fontFamily": "KomikaAxis-Bold",
              "fontSize": "42",
              "primaryColor": "#ff0000",
              "primaryStrokeColor": "#ffffff",
              "secondaryColor": "#ffffff",
              "secondaryStrokeColor": "#000000",
              "textShadowColor": "#00ff2a",
              "vAlign": "end",
              "linesPerFrame": 1,
              "lineHeight": 42,
              "uppercase": false
          },
          "layout": 'fill',
          "elements": []
      }));
    }
  }, [currentWorkspace])
  const handleOk = () => {
    dispatch(setIframeVisibleAction({ state: false }));
  };

  const handleCancel = () => {
    dispatch(setIframeVisibleAction({ state: false }));
  };

  const openIframe = e => {
    e.preventDefault();
    dispatch(setIframeVisibleAction({ state: true }));
  };

  const bannerHandler = val => {
    setIsHideBanner(val);
    window.sessionStorage.setItem('isHideBanner', isHideBanner);
  };


  const showModalUp = () => {
    dispatch(upgradeModalVisibiltyAction(false));
  };

  const handleOkk = () => {
    dispatch(upgradeModalVisibiltyAction(false));
  };

  const handleClose = () => {
    dispatch(upgradeModalVisibiltyAction(false));
  };

  const creatOnboardingApi = async () => {
    dispatch({
      type: types.UPDATE_WORKSPACE,
      payload: { ...currentWorkspace, onBoard: true },
    });
  };
  useEffect(()=>{
    if(location.pathname.trim()==='/'){
      setCurrentPage('sub2')
    }
    if(location.pathname.trim()==='/Branding'){
      setCurrentPage('sub3')
    }
    
  },[location])

  const handleDashboardClicked=()=>{
    dispatch({type: types.SET_TAB_TITLE, payload: 'Home'});
    if(location.pathname.trim()!=='/'){
      setCurrentPage('sub2');
      dispatch(ResetAction(true)); 
      if(!folderChain || folderChain[0]===undefined){
        dispatch(updateFolderChain(currentWorkspace));
      }else{
          const desiredItem = folderChain?.find(folder => folder?.name == currentWorkspace?.name);
          dispatch(updateFolderChain(desiredItem));
      }
      history.push('/');
    }
  }

  const updateCurrentWorkspace = async (item: any) => {

    dispatch({
      type: types.UPDATE_WORKSPACE,
      payload: { ...item },
    });
    dispatch({
      type: types.SET_PROJECTS_TO_EMPTY,
      payload: []
    })
    dispatch(resetFolderChain([]));
    dispatch(updateFolderChain(item));
    dispatch(updateCurrentDirectoryAction(item));
    setTimeout(() => {
      dispatch(
        getAllDirAction({
          workspace: item
            ? item?._id
            : item,
          parentDirectory: null,
          userId: item?.userId,
        }),
      );
      dispatch(getAllProAction({
        workspace: item ? item?._id : item,
        parentDirectory: null,
        userId: item?.userId,
        pageNo: 1,
        size: PAGINATION_LIMIT,
      }))
    }, 200);

  }
  


  return (

    <Route
      {...rest}
      render={props => {
        if (isAuthenticated && currentWorkspace && currentWorkspace.onBoard === true /* && (currentWorkspace.onBoard === true || currentWorkspace?.onBoard === undefined || currentWorkspace?.onBoard === null)*/) {
          return (
            <div className={`vumu-theme`}>
              {fbLoading && (
                <div className="pre-loader">
                  <AppLoader />
                </div>
              )}
              <div className="custom-modal-frame">
                <Modal
                  show={isIframeVisible}
                  effect="fadeInUp"
                  onHide={() => {
                  }}
                >
                  <Modal.Body
                    style={{ width: iFrameWidth, height: iFrameHeight }}
                  >
                    {crossButtonState && (
                      <span className="modal-closer" onClick={handleCancel}>
                        <i className="material-icons">close</i>
                      </span>
                    )}

                    <div
                      style={{ width: '100%', height: '100%' }}
                      id="iframeParent"
                    ></div>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="">
                {/*onClick={showModalUp}*/}
                <BetaModal width="892px" className="upgrade-popup vidprop-theme"
                  visible={upgradeModalVisible} onOk={handleOkk} onCancel={handleClose}
                  footer={false}>
                  <div>
                    <div className="row vertical-divider">
                      <div className="col-md-6">
                        <div className="left-wrap">
                          <div className="d-flex justify-content-center mb-4">
                            <img
                              src={`${config.DESTINATION_MEDIA_CDN}assets/images/upgradeIcon.png`}
                              alt="" />
                          </div>
                          <h3>Why use VidProposals?</h3>
                          <ul>
                            <li>Unlimited shares</li>
                            <li>Unlimited video length</li>
                            <li>Engagement insights for each Video</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="right-wrap">
                          <div className="d-flex justify-content-center mb-4">
                            <img
                              src={`${config.DESTINATION_MEDIA_CDN}assets/images/upgradeIcon.png`}
                              alt="" />
                          </div>
                          <h3><b>You have reached the limit of this Package, </b>please
                                                        upgrade to continue.</h3>
                          <div className="d-flex flex-column align-items-center">
                            <button className="btnAlpha">
                              <div>
                                <StarFilled className="icon-upgrade" />
                              </div>
                              <div>
                                <span>Upgrade now</span>
                                <p className="mb-0">Get unlimited plan.</p>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </BetaModal>
              </div>
              <div className="dashboard-body">
                <Layout className='dashboard_body_innerLayout'>
                  <Sider width={"285px"}
                  // <Sider breakpoint='lg' width={"285px"}
                    // collapsedWidth={window.innerWidth > 767 ? 64 : 0} trigger={null} collapsible
                    collapsedWidth={window.innerWidth > 767 ? 64 : 64} trigger={null} collapsible
                    collapsed={collapsed} onBreakpoint={(broken) => {
                      // setCollapsed(broken);
                    }}>
                    <div className="logo collapseControl" style={{ overflow:'hidden' }}>
                      {/* <span className="min-logo"  style={{ maxWidth: '30px', width: '100%', margin: '0 auto', 'marginLeft': '0' }}><LogoIcon/></span>
                      <span className="max-logo" style={{ marginLeft: '12px', height: '30px' }}><LogoIcon/> Keevi</span> */}               
                      <img className="min-logo" src={currentWorkspace?.workspaceLogo ?? config.DESTINATION_MEDIA_CDN + "assets/logo.png"} style={{ maxWidth: '90%', width: '100%', objectFit:'contain',  margin:'auto'  }}></img>
                      <img className="max-logo" src={currentWorkspace?.workspaceLogo ?? config.DESTINATION_MEDIA_CDN + "assets/logo_full.png"} style={{ marginLeft: '12px', height: '30px' }}></img>
                      {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
                        className: 'trigger collapseDot forWeb',
                        onClick: toggle,
                      })}
                      {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
                        className: 'trigger collapseDot forMob',
                        onClick: toggle,
                      })}
                    </div>
                    <Menu className="firstMenuWrap" mode="inline" selectedKeys={[currentPage]} style={{ marginBottom: "35px" }}>
                      <SubMenu 
                        className="firstMenu" 
                        key="sub2" 
                        icon={<DashboardIcon style={{'fontSize': '28px', color: '#8493a6'}}/>}
                        title={
                          <div className="workSpaceMenuBtn">
                            <Link to="/" className="active dashboardLink" onClick={()=>{handleDashboardClicked()}}></Link>
                            {
                              currentWorkspace &&
                              <p>{currentWorkspace.name}</p>
                            }
                          </div>
                        }
                      >
                        {window.location.pathname == '/Branding' ? "" : allWorkSpaces.map((item:any,idx:any)=><Menu.Item className='workspace-menu-item' key={idx} onClick={()=>updateCurrentWorkspace(item)}>{item.name}</Menu.Item>)}
                      </SubMenu>
                      <SubMenu 
                        className="firstMenu" 
                        key="sub3" 
                        icon={<BgColorsOutlined style={{'fontSize': '28px', color: '#8493a6'}} />}
                        title={
                          <div className="workSpaceMenuBtn">
                            <Link to="/Branding" className="active dashboardLink" onClick={()=>{setCurrentPage('sub3'); dispatch({type: types.SET_TAB_TITLE, payload: 'Branding'})}}></Link>
                            <p>Brand Settings</p>
                            {/* {
                              currentWorkspace &&
                              <p>{currentWorkspace.name}</p>
                            }
                            {
                              currentUser &&
                              <label>{currentUser.fullName}</label>
                            } */}

                          </div>
                        }
                      >
                        {/* <Menu.Item className="subItem" key="11" icon={<img
                          src={config.DESTINATION_MEDIA_CDN + "assets/icons_skining/team_vumu_skining.png"}
                          style={{ maxWidth: '22px', width: '100%' }} />}>
                          <div className="workSpaceMenuBtn">
                            <p>Team Work Space</p>
                            <label>6 Member</label>
                          </div>
                        </Menu.Item> */}
                        {/* <Menu.Item className="subItem" key="12">
                          <div className="createWorkspace">
                            <PlusOutlined className="mr-1" /> Create workspace
                                                    </div>
                        </Menu.Item> */}
                      </SubMenu>

                    </Menu>
                    {/* <Menu className="SimpleMenuWrap" mode="inline" defaultSelectedKeys={['22']}>
                      <Menu.Item key="11" icon={
                        <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 6H21.3C22.2 6 24 6.56471 24 8.82353C24 11.0824 24 18.549 24 22" stroke="#8492A6" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                          <rect x="3.84424" y="10.1113" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="3.84424" y="4.64453" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="3.84424" y="15.5781" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="9.31091" y="10.1113" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="9.31091" y="4.64453" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="9.31091" y="15.5781" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="18.422" y="10.1113" width="2.73333" height="2.73333" fill="#8492A6" />
                          <rect x="18.422" y="15.5781" width="2.73333" height="2.73333" fill="#8492A6" />
                          <path d="M1 22V3.33333C1 2.55556 1.4375 1 3.1875 1C4.9375 1 10.3333 1 12.8125 1C13.5417 1 15 1.46667 15 3.33333C15 5.2 15 16.5556 15 22" stroke="#8492A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      }>
                        Teams
                      </Menu.Item>
                      <Menu.Item key="12" icon={

                        <svg width="25" height="31" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 24H21.5455C22.3636 24 24 24.3273 24 25.6364C24 26.9455 24 29.0909 24 30" stroke="#8492A6" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                          <circle cx="8" cy="13" r="5.1" stroke="#8492A6" stroke-width="1.8" />
                          <circle cx="19.5" cy="16.5" r="3.6" stroke="#8492A6" stroke-width="1.8" />
                          <path d="M19 1V7" stroke="#8492A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M22 4H16" stroke="#8492A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M1.04869 30V23.9048C0.882599 23.2698 1.04869 22 3.04174 22C5.03478 22 10.8478 22 13.5052 22C14.0035 22 15 22.3048 15 23.5238C15 24.7429 15 27.8413 15 30" stroke="#8492A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                      }>
                        Invite team-mates
                      </Menu.Item>
                    </Menu> */}
                    {/* <Menu className="secondMenu" mode="inline" defaultSelectedKeys={['33']}> */}
                      {/* <Menu.Item className="subItem" key="23"
                        icon={<svg className="svgIcon" fill="none" viewBox="0 0 31 27"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="m13.998 3.3649-1e-4 -1.3e-4 -1.5628-2.3648h-8.8022c-1.4427 0-2.6328 1.188-2.6328 2.6645v19.671c0 1.4765 1.1902 2.6645 2.6328 2.6645h23.734c1.4427 0 2.6328-1.188 2.6328-2.6645v-16.432c0-0.71644-0.2746-1.3833-0.7742-1.8872l-15.228-1.6518zm0 0c0.3669 0.55498 0.9854 0.87438 1.6388 0.87438h0.0024l11.728 2.5e-4h7e-4c0.7019 0 1.3587 0.27354 1.858 0.7771l-15.228-1.6517z"
                            stroke-width="2" />
                        </svg>
                        }>
                        <Link className="active dashboardLink"></Link>
                         Folders
                      </Menu.Item> */}
                      {/* <Menu.Item className="subItem" key="13" icon={

                        <svg width="19" height="18" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.251 0.00307072H2.58039C2.2566 -0.0131283 1.9328 0.0348802 1.62763 0.144332C1.32247 0.253784 1.04197 0.422517 0.802287 0.640817C0.562601 0.859117 0.368463 1.12267 0.23105 1.41631C0.0936377 1.70994 0.0156644 2.02786 0.0016197 2.35175C-0.00682672 2.5191 0.0178219 2.68646 0.0741539 2.84427C0.130486 3.00207 0.217395 3.14721 0.329903 3.27138C0.442411 3.39554 0.578309 3.4963 0.729813 3.56786C0.881317 3.63943 1.04545 3.68041 1.21281 3.68845C1.53598 3.69993 1.85174 3.59008 2.098 3.3805C2.34426 3.17092 2.50319 2.87678 2.54354 2.55594H5.61336V11.9288C5.35358 12.0051 5.12528 12.163 4.96222 12.3792C4.79916 12.5953 4.71001 12.8582 4.70795 13.129C4.70768 13.4664 4.84121 13.7902 5.07926 14.0294C5.31731 14.2686 5.64047 14.4036 5.97791 14.4049H7.79371C7.96117 14.4051 8.12702 14.3722 8.28176 14.3081C8.4365 14.2441 8.5771 14.1502 8.69552 14.0318C8.81393 13.9134 8.90784 13.7728 8.97187 13.618C9.03589 13.4633 9.06878 13.2975 9.06865 13.13C9.06675 12.8592 8.97765 12.5962 8.81457 12.38C8.65149 12.1638 8.4231 12.006 8.16324 11.9298V2.55495H11.2301C11.2649 2.86664 11.4133 3.15457 11.6471 3.36369C11.8808 3.5728 12.1834 3.68843 12.497 3.68845H12.5618C12.7291 3.68016 12.8932 3.63897 13.0446 3.56724C13.196 3.49551 13.3317 3.39464 13.4441 3.27041C13.5565 3.14618 13.6433 3.00101 13.6996 2.84321C13.7558 2.68541 13.7804 2.51807 13.772 2.35076C13.742 1.70759 13.4613 1.10173 12.9901 0.662931C12.5189 0.224131 11.8947 -0.0127298 11.251 0.00307072Z" fill="#6B7C94" />
                        </svg>

                      }>
                        <Link to="/templates" className="active dashboardLink">
                        </Link>
                            Templates
                      </Menu.Item> */}
                    {/* </Menu> */}

                    {/* <Menu className="thirdMenu" mode="inline" defaultSelectedKeys={['44']}> */}
                      {/* <Menu.Item className="AppsLink" key="41" icon={
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1" fill="white">
                            <rect width="9" height="9" rx="1" />
                          </mask>
                          <rect width="9" height="9" rx="1" fill="#EDF2F9" stroke="#8492A6" stroke-width="3.6" mask="url(#path-1-inside-1)" />
                          <mask id="path-2-inside-2" fill="white">
                            <rect x="12" width="9" height="9" rx="1" />
                          </mask>
                          <rect x="12" width="9" height="9" rx="1" fill="#EDF2F9" stroke="#8492A6" stroke-width="3.6" mask="url(#path-2-inside-2)" />
                          <mask id="path-3-inside-3" fill="white">
                            <rect x="12" y="12" width="9" height="9" rx="1" />
                          </mask>
                          <rect x="12" y="12" width="9" height="9" rx="1" fill="#EDF2F9" stroke="#8492A6" stroke-width="3.6" mask="url(#path-3-inside-3)" />
                          <mask id="path-4-inside-4" fill="white">
                            <rect y="12" width="9" height="9" rx="1" />
                          </mask>
                          <rect y="12" width="9" height="9" rx="1" fill="#EDF2F9" stroke="#8492A6" stroke-width="3.6" mask="url(#path-4-inside-4)" />
                        </svg>
                      }>
                        <Link to="/tools" className="active dashboardLink">
                        </Link>
                        <span>Tools</span>
                      </Menu.Item> */}
                      {/* <Menu.Item key="42" icon={<img
                                                src={config.DESTINATION_MEDIA_CDN + "assets/icons_skining/chant_skining.png"}
                                                style={{maxWidth: '36px', width: '100%'}}></img>}>
                                                <span>Crisp</span>
                                            </Menu.Item>
                                            <Menu.Item key="43" icon={<img
                                                src={config.DESTINATION_MEDIA_CDN + "assets/icons_skining/peram_skining.png"}
                                                style={{maxWidth: '36px', width: '100%'}}></img>}>
                                                <span>Pexels</span>
                                            </Menu.Item>
                                            <Menu.Item key="44" icon={<img
                                                src={config.DESTINATION_MEDIA_CDN + "assets/icons_skining/slack_skining.png"}
                                                style={{maxWidth: '36px', width: '100%'}}></img>}>
                                                <span>Slack</span>
                                            </Menu.Item> */}
                    {/* </Menu> */}
                    {/* <Menu className="thirdMenu addToolMenu" mode="inline"
                                              defaultSelectedKeys={['55']}>
                                            <Menu.Item className="addApp" key="34">
                                                <span>
                                                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                      d="M9.47586 20V20.5H9.97586H11.0241H11.5241V20V11.5241H20H20.5V11.0241V9.97586V9.47586H20H11.5241V1V0.5H11.0241H9.97586H9.47586V1V9.47586H1H0.5V9.97586V11.0241V11.5241H1H9.47586V20Z"
                                                      fill="#DAE6FF" stroke="#DAE6FF"/>
                                                  </svg>
                                                </span>
                                            </Menu.Item>
                                        </Menu> */}
                  </Sider>

                  <Layout className="site-layout-background"
                    style={{ backgroundColor: '#fff',  minHeight: '280px', }}>
                    <Header />
                    <Content
                      style={{
                        minHeight: 280,
                      }}
                    >
                      {/*<div className="right-panel right-conatiner-space">*/}
                      <div>
                        <Component {...props} />
                      </div>
                    </Content>
                  </Layout>
                </Layout>
                {/* <Footer/> */}
              </div>
            </div>
          );
        }
        // else if (isAuthenticated && currentWorkspace && config.APP === 'KEEVI' /*&& (currentWorkspace?.onBoard === false)*/) {
        //   // No need to display On boarding popup for keevi
          // creatOnboardingApi();
        // }
        else if (isAuthenticated && (currentWorkspace?.onBoard === false)) {
          return (
            <div className={`vumu-theme`} style={{width: '80vw', height: '80vh', margin: 'auto', marginTop: 50}}>
              {fbLoading && (
                <div className="pre-loader">
                  <AppLoader />
                </div>
              )}
              {/* <OnBoardHeader/> */}
              <iframe style={{width: '100%', height: '100%', border: 'none'}} src={`https://form.typeform.com/to/cvQZpdJw#user_id=${currentWorkspace.userId}`}></iframe>
            </div>
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
