import api from '../config';
import * as constants from './constants';
import {
  RenderTextModel,
  RenderVideoModel,
  RenderAudioModel,
  RenderImageModel
} from '../model';
import { store } from '../redux/store';
import { getUToken } from '../helper/auth.helper';
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const productsName: any = {
  "368907": { key: "368907", title: "VUMU Basic (FE)" },
  "368909": { key: "368909", title: "VUMU Elite (FE)" },
  "368913": { key: "368913", title: "VUMU Unlimited (Yearly)" },
  "368915": { key: "368915", title: "VUMU Unlimited (One time)" },
  "368917": { key: "368917", title: "VUMU Unlimited (Trial)" },
  "368919": { key: "368919", title: "VUMU Template Club" },
  "368929": { key: "368929", title: "VUMU Template Club 2" },
  "368921": { key: "368921", title: "VUMUBusiness" },
  "368925": { key: "368925", title: "VUMU Whitelabel + Agency" },
  "368931": { key: "368931", title: "Bundle (FE)" }
}
export const convertToTimeString = (seconds:any) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}
export const convertArrayToDict = (array: any, key: any) => {
  const dict = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, dict);
};

export const updateDict = (hashmap: any, payload: any, key: any) => {
  let dict = Object.assign({}, hashmap);
  if (payload) {
    dict[payload[key]] = payload;
  }
  return dict;
}


export const deleteDict = (dict: any, key: any, value: any) => {
  return Object.fromEntries(Object.entries(dict).filter(([k, v]: any) => v[key] !== value));
}

export const updateDirHelper = (updatedObj: any, prevArray: any, currentParentDirectory: any) => {
  console.log({ updatedObj, prevArray, currentParentDirectory });
  const index = prevArray.findIndex((f: any) => f._id === updatedObj._id);
  const arrayTemp = [...prevArray];
  arrayTemp[index] = { ...updatedObj };
  if (currentParentDirectory) {
    return arrayTemp.filter((arr: any) => arr.parentDirectory === currentParentDirectory)
  }
  if (!currentParentDirectory) {
    return arrayTemp.filter((arr: any) => arr.parentDirectory === null);
  }
  return arrayTemp;
}

export const updateFolderChainHelper = (folderChain: any, payload: any) => {
  // console.log({ folderChain, payload });
  const index = folderChain?.findIndex((f: any) => f?._id === payload?._id);
  // console.log({ index });
  const tempFolderChain = [...folderChain];
  if (index < 0) {
    tempFolderChain.push(payload)
    return tempFolderChain
  };
  tempFolderChain.length = index + 1;
  return tempFolderChain
}

export const downloadHelper = (payload: any) => {
  const pathname = new URL(payload.mp4Url).pathname;
  const Key = pathname.replace('/', '');
  var fileUrl = api.getSignedUrl + Key;
  const postBody = JSON.stringify({ isSource: true, name: Key, expiry: 3600 });
  try {
    fetch(fileUrl, {
      headers: {
        'Authorization': 'Bearer ' + getUToken()
      },
      method: 'POST',
      body: postBody,
    })
      .then((result) => result.json())
      .then((res) => {
        // console.log({ res });

        if (res && res.signed_url) {
          window.open(res.signed_url, '_blank');
        }
      });
  } catch (err) {
    //console.log(err.message);
  }
}


export const DIMENSION_ENUM: any = {
  "0:0": 1,// default ratio of the video
  "1:1": 2,// for ratio 1:1
  "16:9": 3,// for ratio 16:9
  "9:16": 4,// for ratio 9:16
  "4:5": 5,// for ratio 4:5
  "5:4": 6 // for ratio 5:4
}

export const createPayloadForBackend = (tracks: any, duration: any, zoom: any, muted: any, subtitleStyle: any, projectId: any, canvas: any) => {

  let TextPayload = [];
  let VideoPayload = [];
  let AudioPayload = [];
  let ImagePayload = []; //forImage
  let showTimeVideo = 0;
  let previousVideoEnd = 0;
  let previousImageEnd = 0;
  let showTimeImage = 0;
  let videoFrameObj = {};


  //
  let obj = tracks;
  for (let i = 0; i < obj.length; i++) {
    let tempObj = obj[i];

    for (let j = 0; j < tempObj.elements.length; j++) {
      let tempObjElement = tempObj.elements[j];

      if (tempObj.type === constants.VIDEO) {
        if (VideoPayload.length === 0) {
          videoFrameObj = tempObjElement
        }
        showTimeVideo = tempObjElement.start;
        tempObjElement.file = tempObjElement.file ? tempObjElement.file : tempObj.elements[j - 1].file
        VideoPayload.push(new RenderVideoModel({
          ...tempObjElement,
          showTime: showTimeVideo,
          muted: false
          // muted: muted
        }));
        // previousVideoEnd = tempObjElement.end
        //TODO
      } else if (tempObj.type === constants.AUDIO) {
        tempObjElement.file = tempObjElement.file ? tempObjElement.file : tempObj.elements[j - 1].file
        AudioPayload.push(new RenderAudioModel({
          ...tempObjElement,
          showTime: tempObjElement.start,
        }));
      } else if (tempObj.type === constants.TEXT || tempObj.type === constants.SUBS) {
        if (tempObj.type === constants.SUBS) {
          let subsModel = {
            ...tempObjElement,
            ...subtitleStyle,
          }
          TextPayload.push(new RenderTextModel({
            ...subsModel,
            zoom: zoom
          }))
        } else if (tempObj.type === constants.TEXT) {
          TextPayload.push(new RenderTextModel({
            ...tempObjElement,
            zoom: zoom
          }))
        }



      } else if (tempObj.type === constants.IMAGE || tempObj.type === constants.GIF || tempObj.type === constants.EMOJI) {
        showTimeImage = tempObjElement.start;
        ImagePayload.push(new RenderImageModel({
          ...tempObjElement,
          showTime: showTimeImage,
          currentWidth: tempObjElement.divWidth,
          currentHeight: tempObjElement.divHeight,
          zoom: zoom
        }));
      }

    }
  }

  var state = store.getState();
  const profile: any = state.authReducer.profile;

  let frame = {
    framewidth: canvas.canvasWidth,
    frameheight: canvas.canvasHeight,
    duration: duration,
    backgroundcolor: canvas.canvasColor,
    ratio: DIMENSION_ENUM[canvas?.dimensions?.aspectRatio],
    projectId
  }

  return {
    frame,
    videos: VideoPayload,
    audios: AudioPayload,
    texts: TextPayload,
    overlay_videos: [],
    overlay_images: ImagePayload,
    user_id: profile.userId,
    email: profile.email
  }
}

export const generateObjectId = () => {
  return uuidv4().replace(/-/g, '').substr(0, 24).toLowerCase();
}

/**
 * *To seperate the directories and projects and add the value depending upon if it is directory or the project*
 * @param arr Array to be seperated be seperated
 * @param payload the Object that should be added
 * @param toAdd wheather the project is to be added or the directory
 *!toAdd can be: "PROJECT" or "DIRECTORY"!
 * @return Array of the projects and directories
 */

export const addProjectToArray = (arr: any, payload: Object, toAdd: string) => {
  let projects: any = [];
  let signedProjects: any = [];
  let directories: any = [];
  arr.forEach((x: any) => (!x.isDir && x.eSignatureSettings.isSigned ? signedProjects : !x.isDir && !x.eSignatureSettings.isSigned ? projects : directories).push(x));
  toAdd === constants.PROJECT ? projects.unshift(payload) : directories.unshift(payload);
  return [...directories, ...signedProjects, ...projects];
}
export const getMaxDate = (array: any) => {
  const newArray = new Array(...array) // de ref array.
  const sortedArray = newArray.sort(function compare(a: any, b: any) {
    var dateA: any = new Date(a.updatedAt);
    var dateB: any = new Date(b.updatedAt);
    return dateA - dateB;
  });
  if (sortedArray.length === 1) {
    return sortedArray[0]['updatedAt']
  }
  return sortedArray.reduce(function (a: any, b: any) {
    return a.updatedAt > b.updatedAt ? a.updatedAt : b.updatedAt;
  });
}
export const setProjectsLocalStorage = (type: any, payload: any) => {
  localStorage.setItem(type, JSON.stringify(payload));
}

export const getProjectsLocalStorage = (type: any) => {
  let localData: any = localStorage.getItem(type)
  localData = localData ? JSON.parse(localData) : null
  return localData
}
export const removeProjectsLocalStorage = () => {
  localStorage.clear();
}

/**
 *
 * @param oldArray old array that is picked up from the local storage.
 * @param newArray new array that is returned from the server after the successful response
 * @returns the calculated array with updation of the returned from server or new added object returned from server
 * *this function is used to update only the updated object(i.e: project or directory)*
 */

export const _mergeResponseFromServer = (oldArray: any, newArray: any) => {
  let projects: any = [];
  let directories: any = [];
  let _oldArray = new Array(...oldArray);
  let _newArray = new Array(...newArray);
  _oldArray.forEach((x: any) => (!x.isDir ? projects : directories).push(x));
  _newArray.map((nArr: any) => {
    const indPro = projects.findIndex((proj: any) => proj._id === nArr._id);
    const indDir = directories.findIndex((dir: any) => dir._id === nArr._id);
    if (indPro !== -1) {
      projects[indPro] = nArr;
    } else {
      if (!nArr.isDir) {
        projects.unshift(nArr);
      }
    }
    if (indDir !== -1) {
      directories[indDir] = nArr;
    } else {
      if (nArr.isDir) {
        directories.unshift(nArr)
      }
    }
  });

  return [...directories, ...projects];
}

export const generateMonthlyUTCFromNow = () => {
  const localDate = new Date();
  const currentUTC = moment.utc(localDate)

  return {
    lowerValue: moment.utc(localDate).subtract(30, 'days').format(),
    upperValue: currentUTC.format()
  }
}

export const getPackagesTitle = (str: any) => {
  const products = str ? str.split(",") : str;
  const names: any[] = [];
  if (products) {
    products.map((product: any) => {
      names.push(productsName[product]['title']);
    });
    const strNames = names.join("|");
    return strNames;
  }
  return " ";
}

export const nFormatter = (num: any, digits: any) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const numberWithCommas = (x: any) => {
  return x.toLocaleString();
}
export const checkSubdomain = (dbSubDomain: any) => {
  if(dbSubDomain === null) {
    return true
  }
  const hostName = window.location.host.split('.')

  if(hostName.length >=3) {
    // subdomain exists
    console.log(hostName[0])
    return hostName[0] === dbSubDomain
  }
  return true
}
