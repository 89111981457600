export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const SET_PROFILE = 'SET_PROFILE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const UPDATE_ON_BOARDING = 'UPDATE_ON_BOARDING';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const GET_OTHER_USER_DETAILS = 'GET_OTHER_USER_DETAILS';


interface IProps{
    CHECK_AUTHORIZATION: typeof CHECK_AUTHORIZATION;
    SET_PROFILE: typeof SET_PROFILE;
    LOGIN_REQUEST: typeof LOGIN_REQUEST;
}

export type actionType = IProps
