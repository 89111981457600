import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RootState } from './redux/store';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './layout/private';
import PublicRoute from './layout/public';
import { store } from './redux/store';
import { SET_PROFILE } from './redux/auth/types';
import { LOADED_SUCCESS, SET_PERMISSIONS, GET_MONTHLY_VIDEOS_REQUEST } from './redux/app/types';
import AppLoader from './component/Apploader';
import { getSession } from './helper/ust.helper';
import api from './config';
import { generatePermissionJson } from './utils/permissions-helper';
import { generateMonthlyUTCFromNow, getPackagesTitle, checkSubdomain } from './utils/utils';

import 'antd/dist/antd.css';
import './App.scss';
import { UserModel, getFirstNameFromEmail } from './model/user.model';
import { fetchWorkspace, fetchWorkspaces, setUserStatesActions } from "./redux/file-browser/actions";
import { connectSocket } from './helper/websocket'

const Dashboard = lazy(() => import('./container/Dashboard'));
const Template = lazy(
  () => import('./container/Dashboard/Templates/Templates'),
);
const AppsComponent = lazy(
  () => import('./container/Dashboard/Apps/index'),
);

const Settings = lazy(
  () => import('./container/Dashboard/Settings'),
);
const DashboardHtml = lazy(() => import('./component/DashboardHtml'));
const Tools = lazy(() => import('./container/Tools'));
const Login = lazy(() => import('./container/Login'));
const BusinessTools = lazy(() => import('./container/BusinessTools'));
const Branding = lazy(() => import('./container/Branding'));
const Accounts = lazy(() => import('./container/Accounts'));
interface IProps {
  PrivateRoute?: JSX.Element;
}

const intitSnoFlake = (parsedData: any) => {
  // Check if SBF is ready
  if (typeof window.SBF !== 'undefined' && typeof window.SBF.getActiveUser === 'function') {

    window.SBF.getActiveUser(true, function(user: any) {

      if (!user || user.email !== parsedData.email) {
        // User not found or email doesn't match - Create and log in user
        window.SBF.ajax({
          function: "add-user-and-login",
          settings: {
            profile_image: parsedData.picture || "https://board.support/media/docs/user.png",
            first_name: getFirstNameFromEmail(parsedData.fullName) || "User",
            last_name: '',
            email: parsedData.email,
            password: "12345678"
          },
        }, function(response: any) {
          console.error("response ***:", response);
          if (!window.SBF.errorValidation(response)) {
            // Login successful, initialize chat
            window.SBF.loginCookie(response[1]);
            //@ts-ignore
            window.SBF.activeUser(new SBUser(response[0]));
            //@ts-ignore
            SBChat.initChat();
          } else if (response[1] === "duplicate-email") {
            // Duplicate email - Log in existing user and initialize chat
            console.log("duplicate-emai")
            window.SBF.login(parsedData.email, "12345678", "", "", function() {
              //@ts-ignore
              SBChat.initChat();
            });
          } else {
            console.error("Error:", response);
          }
        });
      } else {
        // User already exists - Initialize chat
        //@ts-ignore
        SBChat.initChat();
      }
    });
  } else {
    setTimeout(() => {
      intitSnoFlake(parsedData)
    }, 5000);
    console.error("SBF library not found or missing required functions.");
  }
};

const bootApp = async (dispatch: any) => {
  await getSession()
    .then((session) => {
      const parsedData = new UserModel(session, session.idToken.jwtToken);
      store.dispatch({ type: SET_PROFILE, payload: parsedData });

      dispatch(fetchWorkspace({ userId: parsedData?.userId, email: parsedData.email, token: session.idToken.jwtToken })).then((res: any) => {

        if (!res) {
          return
        }
        const { userId, email, jwtToken, fullName, picture, packages } = parsedData
        const userStatesPayload = {user_id: userId, email: parsedData.email}
        dispatch(setUserStatesActions(userStatesPayload))
        if (checkSubdomain(res.workspaceDomain ? res?.workspaceDomain : null) === false) {
          window.location.href = api.ustRedirectLink
        }

        // intitSnoFlake(parsedData)
  
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = api.CRISP_WEBSITE_ID;
        (function () {
          var d = document;
          var s = d.createElement('script');
          s.src = 'https://client.crisp.chat/l.js';
          s.async = true;
          d.getElementsByTagName('head')[0].appendChild(s);
          // console.log({ window, s });
        })();

        window.$crisp.push(['set', 'user:email', email]);
        window.$crisp.push(['set', 'user:nickname', [fullName]]);
        // window.$crisp.push(['set', 'user:avatar', [picture]])
        // window.$crisp.push(["set", "session:segments", [["firstlogin"]]]);
        // window.$crisp.push(['set', 'session:data', ["products", getPackagesTitle(packages)]])
        const { lowerValue, upperValue } = generateMonthlyUTCFromNow();
        const permissions = generatePermissionJson(packages);
        store.dispatch({ type: SET_PERMISSIONS, payload: permissions });
        store.dispatch({ type: LOADED_SUCCESS });

        const info = {
          userId: userId,
          endDate: upperValue,
          startDate: lowerValue,
          workspace: null
        };
        // console.count("bootApp");
        // store.dispatch({ type: GET_MONTHLY_VIDEOS_REQUEST, payload: info });
        connectSocket(userId, email, jwtToken)
      })
      dispatch(fetchWorkspaces({userId: parsedData?.userId, token: session.idToken.jwtToken}))
    })
    .catch(err => {
      console.log('error while user authentication', err);
      window.location.href = api.appUrl;
    });
  // store.dispatch({ type: LOADED_SUCCESS });

  // store.dispatch({type: LOADED_SUCCESS});

  // boot(res.user_id, res.first_name + res.last_name, jwt);
};

declare let window: any;

const App: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    bootApp(dispatch);
  }, []);

  const loaded = useSelector((state: RootState) => state.appReducer.loaded);
  if (!loaded) {

    return <div>
      {/* <div style={{
        background: '#0080ff',
        color: '#FFFFFF',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 20
      }}>
        <p>
          We are currently undergoing a brief maintenance period to enhance your overall experience and improve performance. We anticipate being back online in just a few hours. If you have any questions or need assistance, please feel free to reach out to us via email at <a href="mailto:support@keevi.io">support@keevi.io</a>. We greatly appreciate your understanding and patience during this time.
        </p>
      </div> */}
      <AppLoader />
    </div>;
  } else {
    return (
      <Suspense fallback={<AppLoader />}>
        <Switch>
          <PrivateRoute exact component={Dashboard} path="/home/*" />;
          <PrivateRoute exact component={Dashboard} path="/" />;
          <PrivateRoute exact component={Template} path="/templates" />;
          <PrivateRoute exact component={AppsComponent} path="/:id/tools" />;
          <PrivateRoute exact component={AppsComponent} path="/tools" />;
          <PrivateRoute exact component={Settings} path="/settings" />;
          <PrivateRoute exact component={BusinessTools} path="/businesstools" />;
          <PrivateRoute exact component={Accounts} path="/accounts" />;
          <PrivateRoute exact component={DashboardHtml} path="/DashboardHtml" />;
          <PrivateRoute exact component={Branding} path="/Branding" />;
          <PrivateRoute exact component={Tools} path="/tools" />;
          <PublicRoute exact component={Login} path="/" />;
          <Redirect from="*" to="/" />;
        </Switch>
      </Suspense>
    );
  }
};

export default App;