import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import '../src/assets/fontsFace/font.css'
import './index.css';
import config from "./config";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <link rel="preload" href={config.iFrameUrl} as="iframe" />
      <Helmet>
        <meta name="description" content={'Send video clips and close deals faster'} />
        <meta charSet="utf-8" />
        <script type="text/javascript" async>
          {`
            var _tip = _tip || [];
            (function (d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.async = true;
            js.src = d.location.protocol + '//app.truconversion.com/ti-js/${config.TC_JS_PATH}';
            tjs.parentNode.insertBefore(js, tjs);
          }(document, 'script', 'ti-js'));
        `}
        </script>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_WEBSITE_ID}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', '${config.GA_WEBSITE_ID}');
          `}
          
        </script>
      </Helmet>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

function noop() { }
if (process.env.REACT_APP_ENV !== 'dev') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
  console.count = noop;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
