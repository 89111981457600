import {SET_PROFILE, UPDATE_ON_BOARDING} from './types';
import { Reducer } from "redux";
import { profileType } from './DataProps';
import { ActionType } from '../interface';

interface initalStateProps {
  isAuthenticated: boolean;
  profile: profileType | null;
  onBoardingData: any | null;
}
const initState: initalStateProps = {
  isAuthenticated: true,
  profile: null,
  onBoardingData: null
}

const authReducer: Reducer<initalStateProps, ActionType> = (state = initState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      // console.log('setting profile')
      return Object.assign({}, state, {
        isAuthenticated: true,
        profile: action.payload
      })
    case UPDATE_ON_BOARDING:
        // console.log('setting profile')
      return Object.assign({}, state, {
        isAuthenticated: true,
        profile: action.payload
      })

    default:
      return state;
  }
}

export default authReducer;
