import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';


import { callApiGateway } from '../../helper/api.helper';
import * as types from './types';

//saga calls

export function* fetchMonthlyStatisticsSaga() {
  yield takeEvery(types.GET_MONTHLY_VIDEOS_REQUEST, function* ({ payload }: any): SagaIterator {
    try {
      const response = yield call(fetchStatisticsReq, payload);
      yield put({
        type: types.GET_MONTHLY_VIDEOS_REQUEST_SUCCESS,
        payload: response.length > 0 ? response[0] : null
      });
    } catch (error) {
      console.log({ error });

    }
  });
}


//api Calls
async function fetchStatisticsReq(payload: any) {
  return await callApiGateway({ url: '/get-statistics', payload, method: 'POST' })
}


export default function* fileBrowserSaga() {
  yield all([fork(fetchMonthlyStatisticsSaga)]);
}
