export const GET_AWS_CREDENTIALS_ACTION = 'GET_AWS_CREDENTIALS_ACTION';
export const SET_AWS_CREDENTIALS = 'SET_AWS_CREDENTIALS';


interface IProps{
    GET_AWS_CREDENTIALS_ACTION: typeof GET_AWS_CREDENTIALS_ACTION;
    SET_AWS_CREDENTIALS: typeof SET_AWS_CREDENTIALS;
}

export type actionType = IProps
