
import { Reducer } from "redux";
import { ActionType } from "../interface";
import { LOADED_SUCCESS, SOCKET_CONNECTED, LOADING_APP, SET_PERMISSIONS, UPGRADE_MODAL_VISIBLE, GET_MONTHLY_VIDEOS_REQUEST_SUCCESS } from './types';

interface initalStateProps {
  loaded: boolean,
  socketConnected: boolean;
  isFreeUser: boolean;
  subscription: string | null;
  permissions: any;
  upgradeModalVisible: boolean;
  monthlyStatistics: any;
}

const initState: initalStateProps = {
  loaded: false,
  socketConnected: false,
  isFreeUser: false,
  subscription: null,
  permissions: {},
  upgradeModalVisible: false,
  monthlyStatistics: null
}

const appReducer: Reducer<initalStateProps, ActionType> = (state = initState, action) => {
  switch (action.type) {
    case LOADED_SUCCESS:
      return {
        ...state, loaded: true
      }
    case LOADING_APP:
      return Object.assign({}, state, {
        loaded: false
      });
    case SOCKET_CONNECTED:
      console.log('socket', action.payload);
      return Object.assign({}, state, {
        socketConnected: true,
        isFreeUser: !['AGENCYOWNER', 'AGENCYUSER'].includes(action.payload.res.subscription),
        subscription: action.payload.res.subscription,
        loaded: true
      });
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      };
    case UPGRADE_MODAL_VISIBLE:
      return {
        ...state,
        upgradeModalVisible: action.payload
      };
    case GET_MONTHLY_VIDEOS_REQUEST_SUCCESS:
      return {
        ...state,
        monthlyStatistics: action.payload
      }
      
    default:
      return state;
  }
}

export default appReducer;